const k = require('./keys')

module.exports = {
  [k.OPEN_DRAWER]: 'open drawer',
  [k.SHARE_YOUR_STORY_WITH_US]: 'Share your story with us',
  [k.THE_ORKA_COMMUNITY_HOPES_TO_BE]: `The Orka Community hopes to be a place for everyone to share their
            insights, concerns, and any experiences they desire. Tell us about
            yours! We are glad to listen and believe your stories will help
            every member of our community.`,
  [k.WHAT_IS_YOUR_STORY]: 'What is your story?',
  [k.ABOUT_YOU]: 'About you',
  [k.SUBMIT]: 'Submit',
  [k.THANKS_FOR_YOUR_SHARING]: 'Thanks for your sharing!',
  [k.FLEX]: 'flex',
  [k.COLUMN]: 'column',
  [k.AUTO]: 'auto',
  [k.PX]: '190px',
  [k.VH]: '100vh',
  [k._]: '',
  [k.PLEASE_FILL_OUT_YOUR_TITLE]: 'Please fill out your title',
  [k.PLEASE_FILL_OUT_YOUR_STORY]: 'Please fill out your story',
  [k.PLEASE_FILL_OUT_YOUR_NAME]: 'Please fill out your name',
  [k.PLEASE_FILL_OUT_YOUR_EMAIL]: 'Please fill out your email',
  [k.LET_S_PUT_YOUR_IDEAS_INTO_REAL]: "Let's Put Your Ideas Into Reality.",
  [k.WE_D_LOVE_TO_HEAR_FROM_YOU_AND]:
    "We'd love to hear from you and change the future together.",
  [k.A_LETTER_FROM_OUR_FOUNDER]: 'A letter from our Founder',
  [k.HI_THERE]: 'Hi there,',
  [k.I_M_BEN_SUN_FOUNDER_OF_ORKA]: "I'm Ben Sun, founder of Orka.",
  [k.IN_THE_PAST_TWO_YEARS_AS_ORKA]: `In the past two years as Orka was refining the products, we have
          benefited so much from our beta testers and customers' input, and we
          very much appreciate it!`,
  [k.I_REMEMBER_THE_TIME_WHEN_ONE_O]: `I remember the time when one of the earliest testers tried our
          prototype, and I saw his eyes glittering:" This is the first time I'm
          using hearing aids in a Starbucks and not getting overwhelmed by the
          noises!" That's when Orka going with the challenge of noise reduction
          got reassured. He was so honest at the same time:" But the sound is a
          little tinny." It was feedback like this saw us relentlessly iterating
          on the product. You gave us confidence boost and you gave us reality
          check.`,
  [k.INNOVATION_TAKES_TIME_TO_MATUR]: `Innovation takes time to mature, and this has only been the beginning
          for Orka, and for the industry. We know hearing aid industry was never
          centered around users, and we want to make a change. Let it be better
          audiology service, marketing messages, or ideas on product innovation,
          it is your constant feedback on what you need and what we can do that
          counts. We'd love to hear from you! So Join us at ground zero and be
          part of the change!`,
  [k.SCHDULE_A_FREE_ORKA_CONSULTATI]: 'Schdule a FREE Orka consultation today',
  [k.SCHEDULE_NOW]: 'Schedule Now',
  [k.OUR_COMMUNITY]: 'Our community',
  [k.LORY_WANG]: 'Lory Wang',
  [k.MY_GRANDFATHER_DOESN_T_WATCH_T]: `My grandfather doesn't watch TV very much, or acts like he doesn't.
            Usually after dinner, the family is sitting in front of the TV,
            grandpa because of stomach trouble, will pace behind the family.
            After putting on his hearing aid, he would aim at the TV as he paced
            behind him after dinner. The TV was on the news, so my grandfather
            didn't quite understand, so he started asking me, and I explained
            some background information. In fact, this news has lasted for a
            long time, but because grandpa has been unable to watch TV, so he
            can not learn some news, a lot of time, has been out of touch with
            the world.`,
  [k.PX1]: '1480px',
  [k.PX2]: '976px',
  [k._1]: '80%',
  [k._2]: '100%',
  [k.SUBSCRIBE]: 'Subscribe',
  [k.TODAY_AND_GET_OFF_YOUR_NE]:
    'today and get $350 off your new hearing aids !',
  [k.YOUR_OFF_CODE]: 'Your $350 OFF code:',
  [k.LABORDAY]: 'LABORDAY2021',
  [k.FFFFFF]: '#ffffff',
  [k.PX3]: '4px',
  [k.POINTER]: 'pointer',
  [k.NONE]: 'none',
  [k.BLOCK]: 'block',
  [k.CENTER]: 'center',
  [k.UPLOAD_AUDIOGRAM]: 'Upload audiogram',
  [k.DRAG_DROP]: 'Drag & Drop',
  [k.YOUR_AUDIOGRAM_HERE]: 'Your audiogram here',
  [k.ONLY_JPG_PNG_AND_PDF]: '(Only *.jpg and *.png images will be accepted)',
  [k.SUBMIT1]: 'SUBMIT',
  [k.MY_AUDIOGRAM]: 'My audiogram',
  [k.UPLOAD_NEW_AUDIOGRAM]: 'UPLOAD NEW AUDIOGRAM',
  [k.DELETING_AUDIOGRAM]: 'Deleting audiogram',
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET]:
    'Are you sure you want to delete current audiogram?',
  [k.CANCEL]: 'Cancel',
  [k.DELETE]: 'Delete',
  [k.PX4]: '128px',
  [k.PX5]: '109px',
  [k.RIGHT]: 'right',
  [k.PX6]: '48px',
  [k._3]: '20%',
  [k.FILE_TYPE_MUST_BE_JPG_JPEG]: 'File type must be .jpg/.jpeg/.png',
  [k.UPLOAD_AUDIOGRAM1]: 'Upload Audiogram',
  [k.UPLOAD]: 'Upload',
  [k.PX7]: '16px',
  [k.PX8]: '340px',
  [k._4]: '600',
  [k.PX9]: '22px',
  [k.PX10]: '40px',
  [k.FILE_TYPE_MUST_BE_IMAGE_PD]: 'File type must be image/*',
  [k.O]: 'O',
  [k.HOW_ARE_YOU]: 'How are you today?',
  [k.MY_CONSULTATION]: 'My consultation',
  [k.THE_UPCOMING_ONE_INFORMATION]: 'The upcoming one information',
  [k._5]: ',',
  [k.AT]: 'At',
  [k.MIN]: '15 min',
  [k.ADD_TO_CALENDAR]: 'Add to calendar',
  [k.GOOGLE_ICAL_OUTLOOK]: 'Google | ical | Outlook',
  [k.RESCHEDULE]: 'RESCHEDULE',
  [k.CANCEL1]: 'CANCEL',
  [k.ARE_YOU_SURE_YOU_WANT_TO_CANCE]:
    'Are you sure you want to cancel this appointment?',
  [k.YOU_MAY_SCHEDULE_A_NEW_APPOINT]:
    'You may schedule a new appointment later.',
  [k.NO]: 'NO',
  [k.YES]: 'Yes',
  [k.YOU_HAVEN_T_SCHEDULED_ANY_CONS]: "You haven't scheduled any consultation.",
  [k.START_NOW]: 'Start Now!',
  [k.SCHEDULE_CONSULTATION]: 'SCHEDULE CONSULTATION',
  [k.ROW]: 'row',
  [k.FLEX_START]: 'flex-start',
  [k.PX11]: '720px',
  [k.PX12]: '660px',
  [k.PX13]: '160px',
  [k.PX14]: '370px',
  [k.PX15]: '25px',
  [k.PX16]: '15px',
  [k.PX17]: '26px',
  [k.PX18]: '20px',
  [k.E_E_E]: '#E4E4E4',
  [k._6]: '#969696',
  [k.ACCOUNT_DETAILS]: 'Account Details',
  [k.EDIT]: 'Edit',
  [k.EDIT_BASIC_INFORMATION]: 'Edit Basic Information',
  [k.YEAR_OF_BIRTH]: 'Year of Birth',
  [k.GENDER]: 'Gender',
  [k.CHANGE_MY_PASSWORD]: 'Change My Password',
  [k.SAVE]: 'SAVE',
  [k.FONTWEIGHTBOLD]: 'fontWeightBold',
  [k.LEFT]: 'left',
  [k._7]: '49.5%',
  [k.CH]: '30ch',
  [k._8]: '50%',
  [k._9]: '79%',
  [k.PX19]: '150px',
  [k._10]: '2%',
  [k._11]: '55%',
  [k.PX20]: '56px',
  [k.FLEX_END]: 'flex-end',
  [k.SPACE_BETWEEN]: 'space-between',
  [k.MEDIUM]: 'medium',
  [k._12]: '********',
  [k.CURRENT_PASSWORD_WRONG_CURREN]:
    'Current Password: Wrong current password.',
  [k.PASSWORD_MUST_BE_CHARACTERS]: 'Password: Must be 6 characters or more.',
  [k.NAME]: 'Name',
  [k.EMAIL]: 'Email',
  [k.PROVINCE]: 'Province',
  [k.PASSWORD]: 'Password',
  [k.NAME1]: 'name',
  [k.EMAIL1]: 'email',
  [k.YEAR]: 'year',
  [k.GENDER1]: 'gender',
  [k.STATE]: 'state',
  [k.PASSWORD1]: 'password',
  [k.ALBERTA]: 'Alberta',
  [k.BRITISH_COLUMBIA]: 'British Columbia',
  [k.MANITOBA]: 'Manitoba',
  [k.NEW_BRUNSWICK]: 'New Brunswick',
  [k.NEWFOUNDLAND_AND_LABRADOR]: 'Newfoundland and Labrador',
  [k.NORTHWEST_TERRITORIES]: 'Northwest Territories',
  [k.NOVA_SCOTIA]: 'Nova Scotia',
  [k.NUNAVUT]: 'Nunavut',
  [k.ONTARIO]: 'Ontario',
  [k.PRINCE_EDWARD_ISLAND]: 'Prince Edward Island',
  [k.QUEBEC]: 'Quebec',
  [k.SASKATCHEWAN]: 'Saskatchewan',
  [k.YUKON_TERRITORY]: 'Yukon Territory',
  [k.NEW_PASSWORD_MUST_BE_CHARAC]:
    'New password: Must be 6 characters or more.',
  [k.PASSWORD_CONFIRMATION_THE_PAS]:
    "Password confirmation: The passwords don't match. please try again.",
  [k.LOG_OUT]: 'Log Out',
  [k.MALE]: 'Male',
  [k.FEMALE]: 'Female',
  [k.OTHERS]: 'Others',
  [k.PX_VW]: '40px 7vw',
  [k.PX21]: '12px',
  [k.FFFFFF1]: '#FFFFFF',
  [k.F_F_F]: '#f2f2f2',
  [k.D_D_D]: '#D8D8D8',
  [k.PX22]: '36px',
  [k._13]: '32%',
  [k.CH1]: '10ch',
  [k.CH2]: '45ch',
  [k.CH3]: '35ch',
  [k.A_A_A]: '#1A1A1A',
  [k._14]: '#979797',
  [k.F_AA_E]: '#415670',
  [k._15]: '#808080',
  [k.PX_SOLID_A_A_A]: '1px solid #1A1A1A',
  [k.PX_SOLID]: '1px solid #979797',
  [k.PX_SOLID_FFBF_D]: '1px solid #FFBF3D',
  [k.PX23]: '14px',
  [k.SMALL]: 'small',
  [k._16]: '400',
  [k._17]: '200',
  [k._18]: '700',
  [k.PX24]: '6px',
  [k.ANYWHERE]: 'anywhere',
  [k.CURRENT_PASSWORD_MUST_BE_CH]:
    'Current Password: Must be 6 characters or more.',
  [k.BIRTH]: 'Birth',
  [k.IN_RETURN_PROCESS]: 'In Return Process',
  [k.ORDER_NUMBER]: 'Order Number:',
  [k.DAY_FREE_HOME_TRY_ON]: '21-Day Free Home Try-On',
  [k.DAY_FREE_HOME_TRY_ON_45]: '45-Day Free Home Try-On',
  [k.ORDER_TOTAL]: 'Order Total',
  [k.FREE]: 'Free',
  [k._19]: '$',
  [k.RETURN_DETAILS]: 'RETURN DETAILS',
  [k.ORDER_SUMMARY]: 'ORDER SUMMARY',
  [k.ESTIMATED_DELIVERED_DATE]: 'Estimated delivered date:',
  [k._20]: ':00-',
  [k._21]: ':00',
  [k.YOUR_LABEL_IS_READY_TO_PRINT]: 'Your label is ready to print.',
  [k.JUST_PRINT_AND_ATTACH_THE_RETU]: `Just print and attach the return label to your package. Please
                deliver your return package within 14 days, otherwise the
                return request will be closed.`,
  [k.PRINT_LABEL]: 'Print Label',
  [k.FIND_A_DROP_OFF_LOCATION]: 'Find a Drop off Location',
  [k.MY_ORDERS]: 'My Orders',
  [k.PX25]: '192px',
  [k.PX26]: '63px',
  [k.PX27]: '111px',
  [k.INLINE]: 'inline',
  [k.PX28]: '32px',
  [k.PX29]: '24px',
  [k.PX30]: '28px',
  [k.PX_PX]: '16px 42px',
  [k.PX_PX_PX_PX]: '32px 40px 48px 40px',
  [k.PX_PX1]: '16px 24px',
  [k._22]: '#474747',
  [k.F_B_B]: '#4F4B4B',
  [k.BLACK]: 'black',
  [k.FFBF_D]: '#FFBF3D',
  [k._23]: '#636363',
  [k._24]: '#404040',
  [k.PX31]: '8px',
  [k.PX32]: '42.5px',
  [k.F_F_F1]: '#F2F2F2',
  [k.F_CB_A]: '#F9CB5A',
  [k.SUPPORT_TIME]: 'Support Time',
  [k.JAN]: 'Jan',
  [k.FEB]: 'Feb',
  [k.MAR]: 'Mar',
  [k.APR]: 'Apr',
  [k.MAY]: 'May',
  [k.JUN]: 'Jun',
  [k.JUL]: 'Jul',
  [k.AUG]: 'Aug',
  [k.SEP]: 'Sep',
  [k.OCT]: 'Oct',
  [k.NOV]: 'Nov',
  [k.DEC]: 'Dec',
  [k.YOUR_LABLE_IS_READY_TO_PRINT]: 'Your lable is ready to print.',
  [k.JUST_PRINT_AND_ATTACH_THE_RETU1]: `Just print and attach the return label to your package. Please deliver
        your return package within 14 days, otherwise the return request will be
        closed.`,
  [k.ORDER_NO]: 'Order No:',
  [k.ESTIMATED_DELIVERY]: 'Estimated Delivery:',
  [k.PX33]: '18px',
  [k.F_B]: '#F7B500',
  [k.F_AA_D]: '#F8AA1D',
  [k._25]: '#838383',
  [k.B_B_B]: '#B2B2B2',
  [k.EAEAEA]: '#EAEAEA',
  [k.F_F_F2]: '#F9F9F9',
  [k.PX_PX2]: '24px 16px',
  [k.PX_PX3]: '0px 16px',
  [k._26]: '0',
  [k.PX_PX4]: '16px 10px',
  [k.DETAIL]: 'detail',
  [k.ACCOUNT_DETAILS1]: 'Account Details',
  [k.ORDER]: 'order',
  [k.SIGNOUT]: 'signout',
  [k.SIGN_OUT]: 'Log out',
  [k.NEED_HELP]: 'Need Help?',
  [k.CANCEL_THE_ORDER]: 'Cancel the order?',
  [k.TRY_ORKA_ONE_TRIAL_PERIOD]: 'Try Orka One 45-trial period',
  [k.CANCEL_SUMMARY]: 'Cancel summary',
  [k.ORKA_ONE_PRE_ORDER_VOUCHER]: 'Orka One pre-order voucher',
  [k.ORDER_NUMBER1]: 'Order number:1209',
  [k.REFUND]: 'Refund: $19.99',
  [k.WHY_DO_YOU_CANCEL_YOUR_ORDER]: 'Why do you cancel your order?',
  [k.PICK_A_REASON_FOR_YOUR_RETURN]: 'Pick a reason for your return',
  [k.PLEASE_MAKE_IT_MANDATORY_TO_AN]: 'please make it mandatory to answer:',
  [k.BOUGHT_IT_SOMEWHERE_ELSE]: 'Bought it somewhere else',
  [k.WAIT_TIME_IS_TOO_LONG]: 'Wait time is too long',
  [k.THE_VOUCHER_OR_ORDER_TOTAL_WAS]:
    'The voucher or order total was different from what I expected',
  [k.DIDN_T_RECEIVE_ENOUGH_CUSTOMER]: "Didn't receive enough customer support",
  [k.FILLED_IN_THE_WRONG_INFORMATIO]: 'Filled in the wrong information',
  [k.OTHER]: 'Other',
  [k.ADDITIONAL_NOTES]: 'Additional notes',
  [k.BACK]: 'BACK',
  [k.PX_PX5]: '12px 0 8px 0',
  [k.PRE_ORDER_DEPOSIT_CANCELED]: 'Pre-Order deposit Canceled',
  [k.YOUR_PRE_ORDER_DEPOSIT_IS_SUCC]:
    'Your pre-order deposit is successfully canceled.',
  [k.CANCELLATION_DETAILS]: 'Cancellation Details',
  [k.PRE_ORDER_DEPOSIT]: 'Pre-order deposit',
  [k.ORDER_CANCELED]: 'Order canceled',
  [k.WE_WILL_AUTOMATICALLY_REFUND_Y]:
    'We will automatically refund you within 5-10 business days',
  [k.BACK_TO_ORDERS]: 'Back to orders',
  [k.IF_YOU_NEED_FURTHER_ASSISTANT]: 'If you need further assistant,',
  [k.PLEASE_CHAT_WITH_US_ONLINE]: 'please chat with us online',
  [k.ORKA_ONE_PREORDER_VOUCHER]: 'Orka One preorder voucher',
  [k.ORDER_NUMBER2]: 'Order number: 1209',
  [k.QUANTITY]: 'Quantity*1',
  [k.ORDER_TOTAL1]: 'Order total: $19.99',
  [k.BACK_TO_ORDERS1]: 'BACK TO ORDERS',
  [k.CHAT_WITH_US_ONLINE]: 'chat with us online!',
  [k.PX34]: '10px',
  [k.YOU_HAVEN_T_PLACED_ANY_ORDERS]: "You haven't placed any orders yet.",
  [k.GO_SHOPPING]: 'GO SHOPPING',
  [k.PRODUCT]: '/Product',
  [k.CLOSE]: 'close',
  [k.SHIPPING_ADDRESS_EDIT]: 'Shipping address edit',
  [k.COUNTRY]: 'Country',
  [k.UNITED_STATES]: 'United States',
  [k.STATE1]: 'State',
  [k.BILLING_ADDRESS_IS_SAME_AS_THE]:
    'Billing address is same as the shipping address',
  [k.BILLING_ADDRESS_EDIT]: 'Billing address edit',
  [k.ORDER_SUMMARY1]: 'Order Summary',
  [k.ORDER_DATE]: 'Order Date:',
  [k.PRINT_INOVICE]: 'Print Inovice',
  [k.TRACK_MY_PACKAGE]: 'Track My Package',
  [k.SHIPPED_WITH_FEDEX_TRACKING]: 'Shipped with Canada Post, tracking#:',
  [k.HTTPS_WWW_FEDEX_COM_FEDEXTRA]:
    'https://www.canadapost-postescanada.ca/track-reperage/en#/details/',
  [k.STANDARD_DELIVERY_BUSINESS]: 'Standard delivery 3-7 business days',
  [k.DELIVERED]: 'Delivered',
  [k.ORDERED]: 'Ordered',
  [k.SHIPPED]: 'Shipped',
  [k.BACK1]: 'Back',
  [k.HOW_TO_VIDEO_QUICK_START_GUI]: 'How-to Video & Quick Start Guide',
  [k.HOW_TO_VIDEO]: 'How-to Video',
  [k.ON_AND_OFF]: 'On and Off',
  [k.YOUR_BROWSER_DOES_NOT_SUPPORT]:
    'Your browser does not support the video tag.',
  [k.YES1]: 'yes',
  [k.NO1]: 'no',
  [k.HOW_TO_WEAR]: 'How to Wear',
  [k.CHARGING]: 'Charging',
  [k.BLUETOOTH_CONNECTION]: 'Bluetooth Connection',
  [k.DOUBLE_TAP]: 'Double Tap',
  [k.QUICK_START_GUIDE]: 'Quick Start Guide',
  [k.ORKA_ONE_HEARING_AIDS_QUICK_ST]: 'Orka One Hearing Aids Quick Start Guide',
  [k.ORDER_DETAILS]: 'Order Details',
  [k.H]: 'h6',
  [k.ORKA_INSIDER_PROGRAM]: 'Orka Insider Program',
  [k.WhAT_IS_INSIDER]: 'Orka Insider Program',
  [k.ORKA_ONE_AI_HEARING_AIDS]: 'Orka One AI Hearing Aids',
  [k.YOUR_TOTAL]: 'Your total',
  [k.SUBTOTAL]: 'Subtotal',
  [k.DISCOUNT]: 'Discount',
  [k._27]: '-$',
  [k.SHIPPING]: 'Shipping',
  [k.TAX]: 'Tax:',
  [k._28]: '$0',
  [k.TOTAL]: 'Total',
  [k.DELIVERY]: 'Delivery',
  [k.DELIVERS_TO]: 'Delivers to',
  [k.CONTACT_INFORMATION]: 'Contact Information',
  [k.PAYMENT_INFOMATION]: 'Payment Infomation',
  [k.PAY_MONTHLY_WITH]: 'Pay with:',
  [k._29]: '**** **** ****',
  [k.BILLING_ADDRESS]: 'Billing Address',
  [k.PRINT_INVOICE]: 'Print Invoice',
  [k.DOWNLOAD_INVOICE]: 'Download Invoice',
  [k.HOW_TO_RETURN_MY_HEARING_AIDS]: 'How to return my hearing aids',
  [k.HOW_TO_RETURN_MY_HEARING_AIDS1]: 'How to return my hearing aids?',
  [k.ONCE_YOUR_DAYS_ARE_UP_PLAC]: `Once your 14 days are up, place your box in the mail with the prepaid
          return label.`,
  [k.ONCE_YOUR_21_DAYS_ARE_UP_PLAC]: `Once your Try-On period is up, place your box in the mail with the prepaid
          return label.`,
  [k.HOW_TO_GET_THE_PREPAID_RETURN]: 'How to get the prepaid return label?',
  [k.NO_WORRIES_RETURNS_ARE_ON_US]: `No worries! Returns are on us. Simply email support@hiorka.com to
          get your return label.`,
  [k.WRITE_A_REVIEW]: 'Write A Review',
  [k.ORKA_IS_DEDICATED_TO_MAKING_HE]: `Orka is dedicated to making healthcare more human-centered by
                being innovative. Whether you're an experienced hearing aid user
                or a new user, we want to know about your experience with hearing
                solutions. Tell us your story and become one of the earliest users
                for Orka's latest models!`,
  [k.WHAT_S_YOUR_STORY]: "What's your story?",
  [k.REVIEW_TITLE]: 'review-title',
  [k.TITLE_OF_YOUR_STORY]: 'Title of your story',
  [k.TITLE]: 'title',
  [k.REVIEW_STORY]: 'review-story',
  [k.YOUR_STORY_HERE]: 'Your story here',
  [k.STORY]: 'story',
  [k.REVIEW]: 'review',
  [k.TRUE]: 'true',
  [k.VIEW_ORDER_DETAILS]: 'View Order Details',
  [k.THANK_YOU_FOR_CHOOSING_ORKA_TO]: `Thank you for choosing Orka to be on your hearing journey. We hope
                you love it!`,
  [k.IF_YOU_VE_ENJOYED_ORKA_HEARING]: `If you've enjoyed Orka hearing aids, would you mind sharing your
                experience on our website? This will help others see how Orka can
                improve their hearing experience.`,
  [k.PAY_IN_FULL]: 'PAY IN FULL',
  [k.CONTAINED]: 'contained',
  [k.INSIDERCHECKOUT_ORDERNUM]: '/Checkout?OrderNum=',
  [k.VIEW_MORE_PRINT_INOVICE]: 'View More',
  [k.VIEW_MORE_PRINT_INVOICE]: 'View More',
  [k.VIEW_MORE_DOWNLOAD_INVOICE]: 'View More & Download Invoice',
  [k.UNSET]: 'unset',
  [k._30]: '30%',
  [k._31]: '60%',
  [k.CALC_PX]: 'calc((100% - 120px) / 2)',
  [k.PX35]: '2px',
  [k.CALC_PX1]: 'calc((100% - 72px) / 2)',
  [k.FIT_CONTENT]: 'fit-content',
  [k.PX36]: '200px',
  [k.PX37]: '300px',
  [k._32]: '90%',
  [k.PX38]: '80px',
  [k.PX39]: '120px',
  [k.PX40]: '232px',
  [k.PX41]: '250px',
  [k.PX42]: '72px',
  [k.PX43]: '38.8px',
  [k.PX44]: '116px',
  [k._33]: '#272727',
  [k.F_F_F3]: '#F4F4F4',
  [k.F_F]: '#423F3F',
  [k.WHITE]: 'white',
  [k.RGBA]: 'rgba(0, 0, 0, 0.25)',
  [k.FFBF_D1]: '#ffbf3d',
  [k.INLINE_BLOCK]: 'inline-block',
  [k.START]: 'start',
  [k.PX45]: '42px',
  [k.PX46]: '56px',
  [k.PX47]: '64px',
  [k.COLUMN1]: 'Column',
  [k.PX48]: '30px',
  [k.PX49]: '38.4px',
  [k.PX50]: '27px',
  [k.PX51]: '19.6px',
  [k.PX52]: '19.2px',
  [k.PX53]: '31px',
  [k._34]: '150%',
  [k._35]: '140%',
  [k.PX54]: '33px',
  [k._36]: '120%',
  [k.UNDERLINE]: 'underline',
  [k.CAPITALIZE]: 'capitalize',
  [k.PX55]: '11px',
  [k.YOU_WILL_SEE_YOUR_TRACKING_NUM]:
    "You will see your tracking number and carrier information here once it's shipped. Due to the COVID-19 pandemic, the shipment might be delayed.",
  [k.DUE_TO_THE_COVID_PANDEMIC]:
    'Due to the COVID-19 pandemic, the shipment might be delayed.',
  [k.YOUR_PACKAGE_WAS_SUCCESSFULLY]:
    'Your package was successfully delivered! Before setting up your Orka One hearing aids, check if your item is in good condition.',
  [k.QTY]: 'QTY*',
  [k.SHIPPING_INFORMATION]: 'Shipping Information',
  [k.SHIPPING_METHOD]: 'Shipping Method',
  [k.STANDARD_DELIVERY_FREE]: 'Standard delivery- Free',
  [k.SHIPPING_ADDRESS]: 'Shipping address',
  [k.PAYMENT_INFORMATION]: 'Payment information',
  [k.BILLING_ADDRESS1]: 'Billing address',
  [k.ORDER_TOTAL2]: 'Order total',
  [k.SUBTOTAL1]: 'Subtotal',
  [k.DISCOUNT1]: 'Discount',
  [k.TAX1]: 'Tax:',
  [k._37]: '$0.00',
  [k.SHIPPING1]: 'Shipping',
  [k.FREE1]: 'free',
  [k.APPLY_FOR_AFTER_SALE_SERVICE]: 'Apply for After-sale Service',
  [k.PLEASE_LOG_IN_USING_THE_EMAIL]: `Please log in using the email address associated with this order to make
        changes.`,
  [k.APPLY_FOR_RETURN]: 'Apply for return',
  [k.BLUR_PX]: 'blur(3px)',
  [k.ALERT_DIALOG_SLIDE_TITLE]: 'alert-dialog-slide-title',
  [k.ALERT_DIALOG_SLIDE_DESCRIPTION]: 'alert-dialog-slide-description',
  [k.ARE_YOU_SURE_TO_APPLY_FOR_A_RE]: 'Are you sure to apply for a refund?',
  [k.NO2]: 'No',
  [k.BACK_TO_TOP]: 'BACK TO TOP',
  [k.PX_SOLID_BLACK]: '1px solid black',
  [k.PX_SOLID_BLACK1]: '0px solid black',
  [k.PAYMENT_SUCCESSFUL]: 'Payment successful!',
  [k.THANK_YOU_FOR_CHOOSING_ORKA_HE]: `Thank you for choosing Orka hearing aids. You're in great company!
            We will send you email notifications every step of the way to ensure
            you get first-hand information on all order processing updates.`,
  [k.MY_ORDERS1]: 'MY ORDERS',
  [k.PAYMENT_SUCCESSFUL1]: 'Payment Successful',
  [k.YOUR_ORDER_HAS_BEEN_PLACED_SUC]: `Your order has been placed successfully, please click Order to check
          order process.`,
  [k.THE_FOLLOWING_ITEM_HAS_BEEN_OR]: 'The following item has been ordered:',
  [k.ORKA_ONE]: 'Orka One',
  [k.AI_HEARING_AIDS]: 'AI hearing aids',
  [k.DEPOSIT_PAIED]: 'Deposit paied $50',
  [k.REMAINING_BALANCE]: 'Remaining balance $1700',
  [k.VIEW_MY_ORDER]: 'VIEW MY ORDER',
  [k.IF_YOU_HAVE_ANY_QUESTIONS]: 'If you have any questions',
  [k.REFUND_SUMMARY]: 'Refund Summary',
  [k.THE_FOLLOWING_ITEM_FOR_RETURN]: 'The following item for return',
  [k.ORDER_NUMBER3]: 'Order number:',
  [k.QUANTITY1]: 'Quantity: 1',
  [k.REFUND1]: 'Refund: $',
  [k.ITEM_RECEIVED]: 'Item Received',
  [k.WE_HAVE_RECEIVED_YOUR_RETURN]: `We have received your return. Your refund will be applied to your
          original method of payment within 7 days if the item is in good
          condition. You will receive an email notification.`,
  [k.BACK_TO_ORDER]: 'BACK TO ORDER',
  [k.IF_YOU_NEED_FURTHER_ASSISTANT1]:
    'If you need further assistant, our online assistant is here to help.',
  [k.INTER]: 'Inter',
  [k._38]: '#616568',
  [k._39]: '#000000',
  [k.WAIT_HERE_IS_A_DAY_FREE_TR]:
    'Wait! Here is a 45-day free trial for you. Why not try it first?',
  [k.UPLOAD_IMAGE_MAX_PHOTOS]: 'Upload image (max 6 photos)',
  [k.REFUND_REASON]: 'Refund Reason',
  [k.YOUR_FEEDBACK_IS_VALUABLE_TO_U]:
    'Your feedback is valuable to us. Please let us know the reason to leave.',
  [k.SELECT_A_REASON_FOR_REFUND]: 'Select a reason for refund',
  [k.BOUGHT_HEARING_AIDS_FROM_OTHER]: 'Bought hearing aids from other brands',
  [k.LATE_DELIVERY_OF_THE_PRODUCT]: 'Late delivery of the product',
  [k.PRODUCT_DID_NOT_MEET_MY_EXPECT]: 'Product did not meet my expectation',
  [k.UNSATISFACTORY_CUSTOMER_SERVIC]: 'Unsatisfactory customer service',
  [k.THE_PRODUCT_WAS_DAMAGED_UPON_A]: 'The product was damaged upon arrival',
  [k.PRODUCT_DOES_NOT_MATCH_DESCRIP]:
    'Product does not match description on website',
  [k.QUALITY_PROBLEM]: 'Quality problem',
  [k.PROBLEM_WITH_ACCESSORIES]: 'Problem with accessories',
  [k.ADDITIONAL_NOTES_OPTIONAL]: 'Additional Notes (Optional)',
  [k.ADDITIONAL_IMAGES_OPTIONAL]: 'Additional images (Optional)',
  [k.THE_CONTENT_OF_THE_POPOVER]: 'The content of the Popover.',
  [k.OUT_OF_RANGE]: 'Out of Range',
  [k.CONTINUE]: 'CONTINUE',
  [k.EASY_REFUNDS]: 'Easy Refunds',
  [k.RED]: 'red',
  [k.REQUEST_AUTHORIZED]: 'Request Authorized',
  [k.WE_HOPE_THAT_YOU_RE_THRILLED_W]: `We hope that you're thrilled with your Orka One but, in the event that
          you wish to return hearing aids you have until 45 days from the day
          you receive your product to send them undamaged back to us for a
          refund.`,
  [k.IF_YOU_NEED_FURTHER_ASSISTANT2]: 'If you need further assistant, please',
  [k.CHAT_WITH_US_ONLINE1]: 'chat with us online',
  [k.PX_PX6]: '32px 64px',
  [k.NORMAL]: 'normal',
  [k.THE_FOLLOWING_ITEM_HAS_BEEN_SU]:
    'The following item has been submitted in the refund request.',
  [k.ORDER_TOTAL3]: 'Order Total: $',
  [k.QTY1]: 'QTY:',
  [k.CHAT_WITH_US_ONLINE2]: 'chat with us online.',
  [k._40]: '#595959',
  [k.D_D_D1]: '#d2d2d7',
  [k.REFUND_CLOSED]: 'Refund Closed',
  [k.SORRY_YOU_HAVEN_T_DELIVERED_Y]: `Sorry, you haven't delivered your return package within 14 days. The
          request has been closed.`,
  [k.GREY]: 'grey',
  [k.PX_PX7]: '7px 30px',
  [k.SORRY_YOUR_ITEM_IS_NOT_IN_THE]:
    'Sorry, your item is not in the 45-day money back guarantee period.',
  [k.APPLY_FOR_A_REFUND]: 'APPLY FOR A REFUND',
  [k.SORRY_YOUR_ITEM_IS_NOT_IN_THE1]:
    'Sorry, your item is not in the 45-day money back guarantee period.',
  [k.PX_PX_PX]: '40px 40px 200px',
  [k.ITEM_DELIVERED]: 'Item Delivered',
  [k.WE_HAVE_RECEIVED_THE_ITEM_YOU]: `We have received the item you return. Our staff will make a refund
          within 5 working days, if the item in good condition, and you would
          receive an email after the refund is issued.`,
  [k.PHONE_NUMBER]: 'Phone Number',
  [k.EMAIL_ADDRESS]: 'Email address',
  [k.WHEN_ARE_YOU_AVAILABLE]: 'When Are You Available?',
  [k.PICK_A_DATE]: 'Pick a Date',
  [k.PICK_A_TIME_SELECT_ALL_THAT_A]: 'Pick a Time (Select all that apply)',
  [k.CANCEL_REQUEST]: 'CANCEL REQUEST',
  [k.BEFORE_SUBMITTING_THE_RETURN_R]:
    "Before submitting the return request, I agree to Orka's",
  [k.RETURN_POLICY]: 'Return Policy.',
  [k.CONFIRM_YOUR_INFORMATION]: 'Confirm Your Information',
  [k.OUR_SUPPORT_TEAM_WILL_CONTACT]: `Our support team will contact you later to help with return
              process.`,
  [k.B_B_B1]: '#b2b2b2',
  [k.PRIMARY]: 'primary',
  [k.PX_PX_PX_PX1]: '32px 64px 32px 48px',
  [k.PX_PX_PX1]: '10px 10px 10px 0',
  [k.PX56]: '35px',
  [k.PX57]: '0px',
  [k.PHONE_NUMBER1]: 'phone number',
  [k.REFUND_ISSUED]: 'Refund Issued',
  [k.YOUR_REFUND_HAS_BEEN_APPLIED_T]: `Your refund has been applied to your original method of payment. The
          processing time varies from 5 - 14 working days.`,
  [k.YOUR_REFUND_HAS_BEEN_ISSUED_T]: `Your refund has been issued. The refund will be credited to the
          account you paid with. Refund times will vary depending on the bank or
          credit card company you're with, and can take up to 14 days.`,
  [k.WANT_TO_REFUND_NO_PROBLEM]: 'Want to refund? No problem!',
  [k.WAIT_WE_OFFER_A_DAY_FREE_T]: `Wait! We offer a 45-day free trial. Why not try it first? All for your
        100% satisfaction.`,
  [k.REFUND_SUMMARY1]: 'Refund summary',
  [k.RETURN_REASON]: 'Return reason',
  [k.WE_TAKE_YOUR_FEEDBACK_TO_HEART]: `We take your feedback to heart. Please let us know the reason you are
        leaving.`,
  [k.PICK_A_REASON_FOR_YOUR_CANCEL]: 'Pick a reason for your cancel',
  [k.FOUND_IT_CHEAPER_SOMEWHERE_ELS]: 'Found it cheaper somewhere else',
  [k.I_DON_T_WANT_IT]: "I don't want it.",
  [k.I_FILLED_IN_THE_WRONG_SHIPPING]: 'I filled in the wrong shipping address.',
  [k.OTHER1]: 'Other.',
  [k.ADDITIONAL_NOTES_OPTIONAL1]: 'Additional notes (Optional)',
  [k.IS_THERE_ANYTHING_WE_COULD_HAV]:
    'Is there anything we could have done differently to improve your overall experience?',
  [k.ADDITIONAL_PHOTOS_FOR_THE_RETU]:
    'Additional photos for the return item (Optional)',
  [k.CONFIRM_YOUR_PERSONAL_INFORMAT]: 'Confirm your personal information.',
  [k.OUR_SUPPORT_STAFF_WILL_CONTACT]:
    'Our support staff will contact you and help you with the return process.',
  [k.WHEN_ARE_YOU_AVAILABLE1]: 'When are you available?',
  [k.PICK_A_TIME]: 'Pick a Time',
  [k.CANCEL_REQUEST1]: 'Cancel Request',
  [k.PX_PX8]: '10px 0px',
  [k.PX_PX9]: '0px 31px',
  [k.BY_SUBMITTING_THE_RETURN_REQUE]:
    "By submitting the return request, I agree to the Orka's ",
  [k.INITIAL]: 'initial',
  [k.OUR_SUPPORT_TEAM_WILL_CONTACT1]: `Our support team will contact you to help check the product at the time
        you have chosen.`,
  [k.REQUEST_SUBMITTED]: 'Request Submitted',
  [k.YOUR_RETURN_REQUEST_IS_SUCCESS]: `Your return request is successfully submitted. We will review it as
          soon as possible.`,
  [k.IF_YOU_NEED_FURTHER_HELP_PLEA]: 'If you need further help, please',
  [k.CHAT_WITH_OUR_ONLINE_ASSISTANT]: 'chat with our online assistant.',
  [k.JANUARY]: 'January',
  [k.FEBRUARY]: 'February',
  [k.MARCH]: 'March',
  [k.APRIL]: 'April',
  [k.JUNE]: 'June',
  [k.JULY]: 'July',
  [k.AUGUST]: 'August',
  [k.SEPTEMBER]: 'September',
  [k.OCTOBER]: 'October',
  [k.NOVEMBER]: 'November',
  [k.DECEMBER]: 'December',
  [k.PX_PX_PX2]: '0px 24px 24px',
  [k.OUR_SUPPORT_TEAM_WILL_CONTACT2]:
    'Our support team will contact you to check the equipment information at the available time you selected.',
  [k.REQUEST_SUBMITED]: 'Request Submited',
  [k.YOU_HAVE_SUBMITTED_THE_RETURN]:
    'You have submitted the return request. Our support team will review your request as soon as possible.',
  [k.ORKA_ONE_HEARING_AIDS]: 'Orka One hearing aids',
  [k.ORDER_NUMBER4]: 'Order Number: ',
  [k.QUANTITY2]: 'Quantity: ',
  [k.REFUND_TOTAL]: 'Refund Total: $',
  [k.IF_YOU_NEED_FURTHER_ASSISTANT3]: 'If you need further assistant, please ',
  [k.REQUEST_UNAUTHORIZED]: 'Request Unauthorized',
  [k.PLEASE_RESUBMIT_YOUR_RETURN_RE]: `Please resubmit your return request to help our support team know more
          about your return reason and they will reissued your request as soon
          as possible.`,
  [k.APPLY_FOR_REFUND]: 'APPLY FOR REFUND',
  [k.REFUND_UNAUTHORIZED]: 'Refund Unauthorized',
  [k.ORDER_NUMBER5]: 'Order number 1209',
  [k.ORDER_CANCELED1]: 'Order Canceled',
  [k.YOUR_ORDER_IS_IN_RETURN_PROCES]: 'Your order is in return process.',
  [k.ORDER_DETAILS1]: 'ORDER DETAILS',
  [k.AM_I_A_CANDIDATE]: 'Am I a Candidate?',
  [k.TAKE_THE_QUIZ]: 'Am I a Candidate?',
  [k.CIRCLE]: 'circle',
  [k.BOTTOM]: 'bottom',
  [k.DOT]: 'dot',
  [k.SABESAN_SATHANANTHAN]: 'sabesan sathananthan',
  [k.A]: 'a',
  [k.ROUNDED]: 'rounded',
  [k.BLANK]: '_blank',
  [k.PX58]: '391px',
  [k.REM]: '10rem',
  [k.D]: '#3d5170',
  [k.B_F]: '#2b394f',
  [k.B]: '#44b700',
  [k._41]: '""',
  [k.FCFCFD]: '#FCFCFD',
  [k.DB_C]: '#DB872C',
  [k.F_D]: '#F7870D',
  [k.D_D_CD]: '#D5D4CD',
  [k.D_D_CD1]: '#D4D4CD',
  [k.INHERIT]: 'inherit',
  [k.ERROR]: '-error',
  [k.TRANSLATE_PX_PX_SCALE]: 'translate(15px, 16px) scale(0.95)',
  [k.TRANSLATEY_PX]: 'translateY(3px)',
  [k.TRANSLATEY_PX1]: 'translateY(5px)',
  [k.F]: '#f03738',
  [k.PX_SOLID_F]: '1px solid #F03738',
  [k.PX_PX_PX3]: '12px 0 6px 15px',
  [k.PX_PX10]: '6px 0 7px',
  [k.PX_FCEBB]: '0 0 0 3px #FCEBB4',
  [k.FDEBEB]: '#FDEBEB',
  [k.S]: '9999s',
  [k.BACKGROUND_COLOR_COLOR]: 'background-color, color',
  [k.SCHEDULE_YOUR_PHONE_CONSULTATI]: 'Schedule your phone consultation',
  [k.MEETING_DURATION_MIN]: 'Meeting duration: 15min',
  [k.NO_SLOTS_AVAILABLE]: 'No slots available.',
  [k.PLEASE_CHOOSE_ANOTHER_DATE]: 'Please choose another date.',
  [k.SLOT]: 'slot',
  [k.ORKA_TEAM_WILL_CALL_YOUR_DURIN]: `Orka team will call your during the time block you select. All
                times are in`,
  [k._42]: '.',
  [k.CONTINUE1]: 'Continue',
  [k.F_F_FB]: '#F4F8FB',
  [k.F_F_F4]: '#F4F4F1',
  [k.SUN]: 'Sun',
  [k.MON]: 'Mon',
  [k.TUE]: 'Tue',
  [k.WED]: 'Wed',
  [k.THU]: 'Thu',
  [k.FRI]: 'Fri',
  [k.SAT]: 'Sat',
  [k.JAN1]: 'JAN',
  [k.FEB1]: 'FEB',
  [k.MAR1]: 'MAR',
  [k.APR1]: 'APR',
  [k.MAY1]: 'MAY',
  [k.JUN1]: 'JUN',
  [k.JUL1]: 'JUL',
  [k.AUG1]: 'AUG',
  [k.SEP1]: 'SEP',
  [k.OCT1]: 'OCT',
  [k.NOV1]: 'NOV',
  [k.DEC1]: 'DEC',
  [k.YYYY_MM_DD]: 'YYYY-MM-DD',
  [k.NO_SLOTS]: 'No Slots',
  [k.AVAILABLE]: 'Available',
  [k.MEETING_DURATION]: 'Meeting Duration',
  [k.ORKA_TEAM_WILL_CALL_YOU_DURING]: `Orka team will call you during the time block you select.
                      All times are in`,
  [k.TRADEIN]: '/TradeIn',
  [k.PX59]: '170px',
  [k.PX60]: '600px',
  [k.PX61]: '180px',
  [k.PX62]: '164px',
  [k.PX63]: '70px',
  [k.A_FF]: '#00a2ff',
  [k.F_F_F5]: '#f4f4f1',
  [k.F_F_F6]: '#F8F8F8',
  [k.HERE_S_YOUR_FIRST_STEP_TO_A_SM]:
    "Here's your first step to a smooth Orka jouney.",
  [k.GET_ON_A_MIN_CONSULTATION_C]:
    'Get on a 15 min consultation call and assess your hearing condition.',
  [k.HAVE_A_MINUTE_CALL_AND_GET]: `Have a 15 minute call and get advice on choosing the right hearing
              solution, assessing your hearing condition, etc.`,
  [k.VOICE_FROM_ORKA_USERS]: 'Voice from Orka Users',
  [k.EVERY_USER_S_OPINION_MATTERS]: "Every user's opinion matters.",
  [k.ALL]: 'All',
  [k.HEARING_FORUMS]: 'Hearing Forums',
  [k.USER_REVIEWS]: 'User Reviews',
  [k.ORKA_TEAM]: 'Orka Team',
  [k.H1]: 'h5',
  [k.PLEASE_CONFIRM_YOUR_AUDIOGRAM]: 'Please confirm your audiogram here',
  [k.YOU_CAN_UPDATE_YOUR_AUDIOGRAM]: 'You can update your audiogram later',
  [k.CONFIRM]: 'CONFIRM',
  [k.UPLOAD_A_NEW_ONE]: 'UPLOAD A NEW ONE',
  [k.PX64]: '100px',
  [k.PX65]: '65px',
  [k.PLEASE_PROVIDE_YOUR_CONTACT_IN]: 'Please provide your contact information',
  [k.BEFORE_CONTINUING_PLEASE_ENSU]: `Before continuing, please ensure you have read and accepted
                Orka's`,
  [k.TERMS_OF_SERVICE]: 'Terms of Service',
  [k.AND]: 'and',
  [k.PRIVACY_POLICY]: 'Privacy Policy',
  [k.ORKA_RESPECTS_YOUR_PRIVACY]: `. Orka respects your privacy, and will keep your data safe and
                secure.`,
  [k.SCHEDULE_NOW1]: 'SCHEDULE NOW',
  [k.PHONE_NUMBER2]: 'Phone number',
  [k.YES_SIGN_ME_UP_FOR_OCCASIONAL]: `Yes, sign me up for occasional marketing emails from Orka
                      about special offers, discounts or product news!`,
  [k.BEFORE_CONTINUING_PLEASE_ENSU1]: `Before continuing, please ensure you have read and accepted
                  Orka's`,
  [k.ORKA_RESPECTS_YOUR_PRIVACY1]: `. Orka respects your privacy, and will keep your data safe and
                  secure.`,
  [k.PX66]: '251px',
  [k._43]: '86%',
  [k.PX67]: '570px',
  [k.PX68]: '50px',
  [k.PX69]: '3px',
  [k.REM1]: '0.875rem',
  [k.REM2]: '0.75rem',
  [k.LEFT1]: 'Left',
  [k.PLEASE_SELECT_YOUR_HEARING_CON]:
    'Please select your hearing consultation type.',
  [k.FIRST_VISIT_MIN]: 'First visit (15min)',
  [k.RETURNING_CUSTOMER_MIN]: 'Returning customer (30min)',
  [k.VH1]: '3vh',
  [k.VH2]: '8vh',
  [k.BODY]: 'body',
  [k.VW]: '100vw',
  [k.MD]: 'md',
  [k.SUCCESS_DIALOG]: 'success-dialog',
  [k.DIALOG_POPCARD]: 'dialog_popCard',
  [k.RGBA1]: 'rgba(1, 1, 1, 0)',
  [k.CARD]: 'card',
  [k.SCHEDULE_AN_APPOINTMENT]: 'Schedule An Appointment',
  [k.BLK]: 'blk',
  [k.TIMES]: 'times',
  [k.SCHEDULE]: 'schedule',
  [k.VW1]: '25vw',
  [k.VW2]: '60vw',
  [k.PX70]: '580px',
  [k.PX71]: '285px',
  [k.PX72]: '400px',
  [k.PX73]: '355px',
  [k.PX74]: '450px',
  [k.PX75]: '350px',
  [k.VH3]: '35vh',
  [k.F_DB]: '#F9DB87',
  [k.F_AA_E1]: '#415670',
  [k.PX76]: '55px',
  [k.ABSOLUTE]: 'absolute',
  [k.YOUR_HEARING_CONSULTATION_IS_S]:
    'Your hearing consultation is scheduled successfully!',
  [k.WHICH_STATE_DO_YOU_LIVE_IN]: 'Which state do you live in?',
  [k.AS_WE_NEED_TO_KNOW_THE_EXACT_T]: 'As we need to know the exact time zone.',
  [k.I_AGREE_TO_THE_TERMS_OF_SERVIC]: `I agree to the Terms of Service, Privacy Policy, and Telehealth
                Consent.`,
  [k.AGREEMENT]: 'agreement',
  [k.TIMESHEET]: 'Timesheet',
  [k.YOU_DID_NOT_AGREE_TO_OUR_PRIVA]:
    'You did not agree to our privacy policy.',
  [k.ALABAMA]: 'Alabama',
  [k.ALASKA]: 'Alaska',
  [k.ARIZONA]: 'Arizona',
  [k.ARKANSAS]: 'Arkansas',
  [k.CALIFORNIA]: 'California',
  [k.COLORADO]: 'Colorado',
  [k.CONNECTICUT]: 'Connecticut',
  [k.DELAWARE]: 'Delaware',
  [k.FLORIDA]: 'Florida',
  [k.GEORGIA]: 'Georgia',
  [k.HAWAII]: 'Hawaii',
  [k.IDAHO]: 'Idaho',
  [k.ILLINOIS]: 'Illinois',
  [k.INDIANA]: 'Indiana',
  [k.IOWA]: 'Iowa',
  [k.KANSAS]: 'Kansas',
  [k.KENTUCKY]: 'Kentucky',
  [k.LOUISIANA]: 'Louisiana',
  [k.MAINE]: 'Maine',
  [k.MARYLAND]: 'Maryland',
  [k.MASSACHUSETTS]: 'Massachusetts',
  [k.MICHIGAN]: 'Michigan',
  [k.MINNESOTA]: 'Minnesota',
  [k.MISSISSIPPI]: 'Mississippi',
  [k.MISSOURI]: 'Missouri',
  [k.MONTANA]: 'Montana',
  [k.NEBRASKA]: 'Nebraska',
  [k.NEVADA]: 'Nevada',
  [k.NEW_HAMPSHIRE]: 'New Hampshire',
  [k.NEW_JERSEY]: 'New Jersey',
  [k.NEW_MEXICO]: 'New Mexico',
  [k.NEW_YORK]: 'New York',
  [k.NORTH_CAROLINA]: 'North Carolina',
  [k.NORTH_DAKOTA]: 'North Dakota',
  [k.OHIO]: 'Ohio',
  [k.OKLAHOMA]: 'Oklahoma',
  [k.OREGON]: 'Oregon',
  [k.PENNSYLVANIA]: 'Pennsylvania',
  [k.RHODE_ISLAND]: 'Rhode Island',
  [k.SOUTH_CAROLINA]: 'South Carolina',
  [k.SOUTH_DAKOTA]: 'South Dakota',
  [k.TENNESSEE]: 'Tennessee',
  [k.TEXAS]: 'Texas',
  [k.UTAH]: 'Utah',
  [k.VERMONT]: 'Vermont',
  [k.VIRGINIA]: 'Virginia',
  [k.WASHINGTON]: 'Washington',
  [k.WEST_VIRGINIA]: 'West Virginia',
  [k.WISCONSIN]: 'Wisconsin',
  [k.WYOMING]: 'Wyoming',
  [k.SCHEDULE_YOUR_HEARING_CONSULTA]:
    'Schedule your hearing consultation below',
  [k.PICK_A_DATE1]: 'Pick a date',
  [k.MONTH]: 'month',
  [k.DAYS]: 'days',
  [k.YOUR_HEARING_CONSULTATION_WILL]: `Your hearing consultation will take 15 min. From this learn
                    more about Orka,`,
  [k.OUR_HEARING_AIDS_AND_HOW_WE_CA]:
    'our hearing aids and how we can improve your hearing health.',
  [k.PICK_A_SLOT]: 'Pick a slot',
  [k.YYYY_MM_DD_HH_MM_A]: 'YYYY-MM-DD HH:mm a',
  [k.PX77]: '210px',
  [k.PX78]: '90px',
  [k.PX79]: '430px',
  [k.PX80]: '500px',
  [k.PLEASE_UPLOAD_YOUR_AUDIOGRAM_H]: 'Please upload your audiogram here',
  [k.YOUR_AUDIOLOGIST_NEED_TO_KNOW]: `Your audiologist need to know more about hearing conditions to give
            you appropriate`,
  [k.ADVICE_ON_NEXT_STEPS_PLEASE_U]:
    'advice on next steps. Please upload your audiogram here.',
  [k.UPLOAD_AUDIOGRAM2]: 'UPLOAD AUDIOGRAM',
  [k.UPDATE_LATER]: 'UPDATE LATER',
  [k.BOOK_A_CALL]: 'book a call',
  [k.TRANSPARENT]: 'transparent',
  [k.HOVER]: ':hover',
  [k.DONE]: 'DONE',
  [k.WANNA_FREE_SUPPLIES_LEAVE_YOU]: `Wanna free supplies? Leave your shipping address and it will be sent
          to you in a minute.`,
  [k.FREE_SUPPLY_GIFTS]: 'Free supply gifts',
  [k.FREE_TIPS_WAX_GUARDS_AND_BRUS]:
    'Free tips, wax guards and brushes, get it now!',
  [k.LEAVE_YOUR_SHIPPING_ADDRESS_HE]: 'Leave your shipping address here',
  [k._44]: '93.2%',
  [k._45]: '62.63%',
  [k._46]: '66%',
  [k.PX81]: '7px',
  [k.PX82]: '-4px',
  [k.PX83]: '60.79px',
  [k.PX_PX_PX_PX2]: '12px 0px 0px 12px',
  [k.LINEAR_GRADIENT_TO_RIGHT_F_A]:
    'linear-gradient(to right, #415670, #ff7b69)',
  [k.APT_SUIT_REQUIRED_FIELD]: 'Apt/Suit: Required field.',
  [k.STREET_ADDRESS_REQUIRED_FIELD]: 'Street Address: Required field.',
  [k.CITY_REQUIRED_FIELD]: 'City: Required field.',
  [k.PROVINCE_REQUIRED_FIELD]: 'Province: Required field.',
  [k.POSTAL_CODE_REQUIRED_FIELD]: 'Postal Code: Required field.',
  [k.POSTAL_CODE_INVALID_POSTAL_CO]: 'Postal Code: Invalid postal code.',
  [k.PHONE_REQUIRED_FIELD]: 'Phone: Required field.',
  [k.PHONE_PLEASE_PROVIDE_A_VALID]:
    'Phone: Please provide a valid phone number.',
  [k.NEXT]: 'Next',
  [k.PX84]: '222px',
  [k.HEART]: 'heart',
  [k.THANK_YOU]: 'Thank You!',
  [k.THANKS]: 'thanks',
  [k.PX85]: '17.5px',
  [k.VW3]: '90vw',
  [k.PX86]: '579px',
  [k.YOU_HAVE_NOT_COMPLET]: `You have not
          completed the form, please fill out all required fields.`,
  [k.REM3]: '1.375rem',
  [k.F1]: '#F03738',
  [k.FALSE]: 'false',
  [k.NOT_AT_ALL_LIKELY]: 'NOT AT ALL LIKELY',
  [k.EXTREMELY_LIKELY]: 'EXTREMELY LIKELY',
  [k.YOU_HAVE_NOT_COMPL]: `You have not
            completed the form, please fill out all required fields.`,
  [k.PX87]: '118px',
  [k.PX88]: '29px',
  [k.WE_LL_SEND_THE_SUPPLIES_TO_YOU]: `We'll send the supplies to you within one week, thank you for your
              feedback!`,
  [k.USAGE_GIFT]: 'Usage-gift',
  [k.USAGE_RECEIVED]: 'Usage-received',
  [k.YOUR_COUPON_CODE_IS_READY_AND]: `Thanks  for your valuable feedback. As an appreciation of your time, we have sent a $20 coupon to your email, which you may apply when placing your order on the chekout page.`,
  [k.IT_S_A_GIFT_FORWARD_THIS_SURV]:
    'We would love to hear more from you! Please help forward this survey if this is a gift to someone else.',
  [k.ASK_THEM_TO_COMPLETE_THE_SURVE]: `Ask them to complete the survey and get more domes, wax guards and
          cleaning brush FOR FREE!`,
  [k.YOUR_FREE_SUPPLIES_ARE_ON_THE]: 'Your free supplies are on the way',
  [k.THE_FEEDBACK_ON_HEARING_AID_US]:
    'The feedback on hearing aid use means a lot to us!',
  [k.SHARE_VIA]: 'Share via',
  [k.SRC_IMGS_FEEDBACK]: '/imgs/feedBack/',
  [k.OR_SHARE_THE_LINK_NOW]: 'Or share the link now',
  [k.HTTPS_WWW_HIORKA_CA_FEEDBACK]: 'https://www.hiorka.com/Survey/Feedback-1',
  [k.LINK_COPIED]: 'Link copied',
  [k.SALES_AND_REFUND_POLICY]: 'Sales and Refund Policy',
  [k.COPYRIGHT_ORKA_LABS_INC]:
    'Copyright © 2023 Orka Labs Inc. All rights reserved.',
  [k._47]: '40%',
  [k.HTTPS_WWW_FACEBOOK_COM_LOGIN]: 'https://www.facebook.com/hiorka',
  [k.HTTPS_TWITTER_COM_ORKAHEARIN]: 'https://twitter.com/OrkaHearingAids',
  [k.HTTPS_WWW_LINKEDIN_COM_COMPA]:
    'https://www.linkedin.com/company/orkahearingaids/mycompany/',
  [k.A_LETTER_FROM_OUR_FOUNDER1]: 'A Letter From Our Founder',
  [k.INVITATION_TO_JOIN_US_AT_GROUN]:
    'Invitation to join us at ground zero and change the industry',
  [k.CHECK_IT_NOW]: 'CHECK IT NOW',
  [k.ORKA_AIMS]:
    'Orka aims to eliminate all the uncertainty and inconveniences throughout your hearing journey. That is why we launched the Insider Program so you can try hearing aids with premium technology with zero obligation for free. You will also receive complementary audiologist service to get your started.',
  [k.HOW_ORKA_INSIDER_PROGRAM_WORKS]: 'Free Home Try-On in 4 Easy Steps',
  [k.VW4]: '80vw',
  [k.CALC_PX2]: 'calc(25% - 36px)',
  [k.PX89]: '130px',
  [k._48]: '500',
  [k.BLCAK]: 'blcak',
  [k.F_F_F7]: '#F1F1F1',
  [k.HTTPS_CA_HIORKA_STORAGE_S_C]:
    'https://legacy-cdn.hiorka.com/imgs/ca-frontend/bluetooth_poster.jpg',
  [k.VIDEO_MP_CODECS_AVC_E_E]: 'video/mp4;codecs="avc1.42E01E"',
  [k.SIMPLE_HEARING_SOLUTION_YOU_CA]:
    'Simple Hearing Solution you can get from Home',
  [k.PX90]: '-350px',
  [k._49]: '-1',
  [k.A_A_A1]: '#1a1a1a',
  [k.LINEAR_GRADIENT_TO_BOTTOM_RGBA]:
    'linear-gradient(to bottom,rgba(26,26,26,0),rgba(26,26,26,1))',
  [k._50]: '800',
  [k.EXCEPTIONAL_SPEECH_PNG]: 'exceptional_speech.png',
  [k.BLUETOOTH_PNG]: 'bluetooth_5.png',
  [k.FITTING_ONLINE_PNG]: 'fitting_online.png',
  [k.AFFORDABLE_PRICE_PNG]: 'affordable_price.png',
  [k.BETTER_HEARING_FOR_YOUR_WAY_OF]: `Don't Miss Another Moment`,
  [k.GET_STARTED]: 'GET STARTED',
  [k.I_AGREE_TO_THE_ORKA_S]: "I agree to the Orka's",
  [k.TERMS_OF_SERVICE1]: 'Terms of Service,',
  [k.THINK_YOU_HAVE_HEARING_LOSS]: 'Think you have hearing loss?',
  [k.TAKE_A_QUICK_SURVEY_TO_SEE_IF]: `Start Today with 45 Day Free Home Try-On.`,
  [k.TAKE_A_QUIZ]: 'Take a QUIZ',
  [k.WHITE_IMPORTANT]: 'white !important',
  [k.PLEASE_AGREE_TO_OUR_TERMS_OF_S]:
    'Please agree to our terms of services and privacy policy',
  [k.INVALID_EMAIL_ADDRESS]: 'Invalid email address',
  [k.EXPERIENCE_HOW_ORKA_S_WILL_HEL]:
    "Experience how Orka's will help with your way of life. Affordable and Effortless hearing solution that works for you.",
  [k.SURVEYPAGE]: '/SurveyPage',
  [k.HTTPS_CA_HIORKA_STORAGE_S_C1]:
    'https://legacy-cdn.hiorka.com/imgs/ca-frontend/header_poster.jpg',
  [k._51]: '10',
  [k.MEET_ORKA_ONE]: 'Meet Orka One',
  [k.SHOP_LIST]: 'shop-list',
  [k.PURCHASE_ORKA_ONE]: 'Purchase Orka One',
  [k.FREE_HOME_TRY_ON]: 'Free Home Try-On',
  [k.CONTACT]: 'Contact',
  [k.HOVER1]: 'hover',
  [k.EMAIL2]: 'Email:',
  [k.CA_SUPPORT_HIORKA_COM]: 'support@hiorka.com',
  [k.PHONE]: 'Phone:',
  [k._52]: '(800) 653-6022',
  [k.ORKA_LABS_INC]: 'Orka Labs Inc.',
  [k.S_WACKER_DRIVE_SUITE]: '125 S Wacker Drive, Suite 300',
  [k.CHICAGO_IL]: 'Chicago, IL 60606',
  [k.ACCOUNT]: 'Account',
  [k.LOG_IN]: 'Log In',
  [k.TRY_FREE_HOME]: 'try_free_home',
  [k.FREE_TRY_ON]: 'Free Try-On',
  [k.ORKA_LOGO]: 'orka logo',
  [k.PX91]: 'px',
  [k.CONTACT_IMG]: 'contact img',
  [k._53]: '30 - 44',
  [k._54]: '45 - 54',
  [k._55]: '55 - 64',
  [k._56]: '65 - 75',
  [k._57]: '75 +',
  [k.START_TEST]: 'Start Test',
  [k.H2]: 'h4',
  [k.BEFORE_YOU_BEGIN_YOUR_HEARING]: 'Before you begin your HEARING TEST',
  [k.OUTLINED]: 'outlined',
  [k.PRESURVEY]: 'PreSurvey',
  [k.LET_S_START]: "LET'S START",
  [k.PLEASE_SET_YOUR_VOLUME_TO_A_CO]:
    'Please set your volume to a comfortable level.',
  [k.SUBTITLE]: 'subtitle2',
  [k.TO_GET_THE_BEST_RESULTS]: 'To get the best results:',
  [k.IF_YOU_USE_APPLE_AIRPODS_OR_E]:
    '-if you use Apple AirPods or EarBuds set your volume to',
  [k.MAXIMUM]: 'maximum',
  [k.IF_YOU_USE_SPEAKERS_OR_HEADPH]:
    '-if you use speakers or headphones set your volume level to',
  [k.A_MAXIMUM_COMFORTABLE_LEVEL]: 'a maximum comfortable level.',
  [k.IMAGE]: 'image',
  [k.CONTAIN]: 'contain',
  [k.IMAGE1]: 'image2',
  [k.DONE1]: 'DONE!',
  [k.NEXT1]: 'NEXT',
  [k.START1]: 'START',
  [k.PRESS]: 'PRESS',
  [k.VIEW_RESULTS]: 'VIEW RESULTS',
  [k.PX92]: '371px',
  [k.PX93]: '37px',
  [k._58]: '#898989',
  [k.VH4]: '15vh',
  [k.PX94]: '667px',
  [k.PX95]: '278px',
  [k.VH5]: '12vh',
  [k.K]: '1k',
  [k.K1]: '2k',
  [k.K2]: '4k',
  [k.K3]: '8k',
  [k._59]: '125',
  [k._60]: '250',
  [k.UP_TO]: 'UP TO',
  [k._61]: '1',
  [k._62]: '2',
  [k._63]: '3',
  [k.REM4]: '6rem',
  [k.REM5]: '7rem',
  [k.REM6]: '4rem',
  [k.REM7]: '9rem',
  [k.REM8]: '15rem',
  [k.REM9]: '5rem',
  [k.REM10]: '14rem',
  [k.REM11]: '1rem',
  [k.REM12]: '1.35rem',
  [k.REM13]: '1.125rem',
  [k.REM14]: '1.5rem',
  [k.REM15]: '-2rem',
  [k.REM16]: '-0.4rem',
  [k.REM17]: '3rem',
  [k.REM18]: '16rem',
  [k.FFF]: '#FFF',
  [k._64]: '-10%',
  [k.HOURS]: 'hours',
  [k.OF_USING_TIME_WITH_MULTIPLE_CH]:
    'of using time with multiple charging in the chargin box.',
  [k.ORKA_ONE_ALL_IN_ONE]: 'Orka One, all in one.',
  [k.THE_WHOLE_WORLD_IN_YOUR_EARS_A]: 'The whole world in your ears again.',
  [k.CUTTING_EDGE_AI_DENOISE_TECHNO]: 'Cutting-edge AI DeNoise technology.',
  [k.ORKA_ONE_IS_EQUIPPED_WITH_ADVA]: `Orka One is equipped with advanced machine learning algorithms and
            refined sound processing systems. That's how we bring melodious
            sounds into your ears.`,
  [k.INSTANT_NOISE_REDUCTION_ANYTI]:
    'Instant noise reduction, anytime, anywhere.',
  [k.ORKA_ONE_FILTERS_OUT_BACKGROUN]: `Orka One filters out background noises while enhancing human voices
            in real-time. Wherever you are, the filters adapt to changes in the
            surroundings and ensures a clear and quality sound.`,
  [k.TUNE_INTO_THE_WORLD]: 'Tune into the world.',
  [k.HEAR_WHAT_MATTERS_AND_BE_AT_E]:
    'Hear what matters, and be at ease on this noisy planet.',
  [k.SPRINKLE_SOME_CREATIVITY_INTO]:
    'Sprinkle some creativity into the technology.',
  [k.THE_LIGHTWEIGHT_YET_COMPACT_DE]: `The lightweight yet compact design makes wearing Orka One so
            comfortable that you may forget you have it on.`,
  [k.BLUETOOTH]: 'Bluetooth 5.3',
  [k.CONNECT_WIRELESSLY_TO_YOUR_IOS]:
    'Connect wirelessly to your iOS® or Android devices.',
  [k.EFFORTLESS_HEARING_EXPERIENCE]: 'Effortless hearing experience',
  [k.WHEN_A_PHONE_CALL_COMES_IN_OR]: `When a phone call comes in, Orka One automatically switches from
                hearing aid mode to phone-call mode.`,
  [k.HANDS_FREE_CALLING]: 'Hands-free calling',
  [k.GET_A_PHONE_CALL_SIMPLY_DOUBL]: `Get a phone call? Simply double-tap either of the hearing aids to
                  answer.`,
  [k.ORKA_HEALTH_APP]: 'Orka Health App',
  [k.CONTROL_THE_DEVICES_REMOTELY]: `Control the devices remotely, manage your Bluetooth connections,
              get advice from professional audiologists, and more. Download the
              free Orka Health app now.`,
  [k.EASY_HEARING_ALL_DAY_LONG]: 'Easy Hearing All Day Long',
  [k.HASSLE_FREE_WITH_RECHARGEABLE]:
    'Hassle-free with rechargeable batteries and portable charging case.',
  [k._65]: '30',
  [k.MINS]: 'mins',
  [k.OF_AI_DENOISE_HEARING]: 'of AI DeNoise hearing',
  [k.ON_A_SINGLE_CHARGE]: 'on a single charge.',
  [k.OF_CONTINUOUS_HEARING]: 'of continuous hearing',
  [k.WITH_CHARGING_CASE]: 'with charging case.',
  [k.FAST_CHARGING]: 'fast charging',
  [k.KEEP_SCROLLING]: 'Keep scrolling',
  [k.CUTTING_EDGE_AI_DENOISE_TECHNO1]: 'Cutting-edge AI DeNoise technology',
  [k.ORKA_ONE_IS_EQUIPPED_WITH]: 'Orka One is equipped with',
  [k.ADVANCED_MACHINE_LEARNING_ALGO]: 'advanced machine learning algorithms',
  [k.AND_REFINED_SOUND_PROCESSING_S]:
    "and refined sound processing systems. That's now we bring",
  [k.MELDIOUS_SOUNDS]: 'meldious sounds',
  [k.INTO_YOUR_EARS]: 'into your ears.',
  [k.ORKA_ONE_FILTERS_OUT_BACKGROUN1]: `Orka One filters out background noises while enhancing human
              voices in real-time. Wherever you are, the filters adapt to
              changes in the surroundings and ensures a clear and quality sound.`,
  [k.SPRINGKLE_SOME_CREATIVITY_INTO]:
    'Springkle some creativity into the technology.',
  [k.WHEN_A_PHONE_CALL_COMES_IN_OR1]: `When a phone call comes in, Orka One automatically switches from
              hearing aid mode to phone-call mode.`,
  [k.GET_A_PHONE_CALL_SIMPLY_DOUBL1]:
    'Get a phone call? Simply double-tap on either of the earbuds to answer.',
  [k.CONTROL_THE_DEVICES_REMOTELY1]: `Control the devices remotely, manage your Bluetooth connections,
              get advice from professional audiologists, and more.`,
  [k.DOWNLOAD_THE_FREE]: 'Download the free',
  [k.ORKA_HEALTH_APP1]: 'Orka Health app',
  [k.NOW]: 'now.',
  [k.CURRENT]: 'current',
  [k.PRE]: 'pre',
  [k.POST]: 'post',
  [k.BUY]: 'Buy',
  [k.AI_DENOISE]: 'AI DeNoise',
  [k.DESIGN]: 'Design',
  [k.CHARGING_CASE]: 'Charging Case',
  [k._66]: '0%',
  [k._67]: '25%',
  [k.HEARING_AIDS]: 'Hearing Aids',
  [k.RGBA2]: 'rgba(26,26,26,0.7)',
  [k.RGBA3]: 'rgba(25,25,25,0.75)',
  [k.REM19]: '2.5rem',
  [k.REM20]: '1.1rem',
  [k.REM21]: '1.25rem',
  [k.PX_PX_PX_PX3]: '20px 64px 32px 64px',
  [k.PX_PX_PX_PX4]: '48px 64px 48px 64px',
  [k.FAST_CHARGING1]: 'Fast Charging',
  [k.PX96]: '-6px',
  [k.FOOT]: 'foot',
  [k.LETTERDIV]: 'LetterDiv',
  [k.RECHARGE]: 'recharge',
  [k._68]: '85%',
  [k.PX97]: '255px',
  [k.ROW_REVERSE]: 'row-reverse',
  [k.VH6]: '80vh',
  [k.CALC_VH_PX]: 'calc(100vh + 1000px)',
  [k.VH7]: '50vh',
  [k.PX98]: '38px',
  [k.TRANSLATEY]: 'translateY(-10%)',
  [k.TRANSLATEY1]: 'translateY(10%)',
  [k.RELATIVE]: 'relative',
  [k.PX99]: '-50px',
  [k.PX100]: '-1px',
  [k.PX101]: '-3px',
  [k.PX_PX11]: '0px 20px',
  [k.PX_PX_PX_PX5]: '0px 0px 0px 20px',
  [k._69]: '10%',
  [k.ORKA_MESSAGE_BOARD]: 'Orka Message Board',
  [k.CLICK_TO_SEE_IF_OTHER_PEOPLE_S]:
    "Click to see if other people's thoughts may help!",
  [k.CHOOSING_THE_RIGHT_HEARING_AID]: `Choosing the right hearing aids is not a snap decision, and no one
            has to be alone in doing this.`,
  [k.JOIN_THE_CONVERSATION]: 'join the conversation',
  [k.PX_PX12]: '115px 130px',
  [k.VH_VW]: '5vh 8vw',
  [k._70]: '45%',
  [k.ALL_IN_ONE_HEARING_SOLUTION]: 'All-in-one hearing solution',
  [k.MORE_FUNCTIONS_MORE_FUN]: 'More functions, more fun',
  [k.BURDEN_FREE_PURCHASE]: 'Burden-free purchase',
  [k.REMOTE_AUDIOLOGY_CARE]: '7/24 remote audiology care',
  [k.POWERED_BY_AI_DENOISE_TECHNOLO]:
    'Powered by AI DeNoise technology and Bluetooth 5.3',
  [k.DAY_MONEY_BACK_GUARANTEE]: '45-day money-back guarantee',
  [k.ALL_IN_ONE_HEARING_SOLUTION_FO]: 'all in one hearing solution for users',
  [k.PLAY_MORE_FUNCTION_ON_ORKA_AND]: 'play more function on Orka and have fun',
  [k.MONEY_BACK_GUARANTEE_IN_DAY]: 'money-back guarantee in 45 days',
  [k.THE_HEARING_AIDS_FOR_YOUR_EVER]: 'The Hearing aids for your every need',
  [k.GET_YOUR_AWARD_WINNING_HEARING]:
    'Get your award-winning hearing aids today.',
  [k.PRE_ORDER]: 'PRE-ORDER',
  [k._71]: '09',
  [k._72]: '24',
  [k.NOW_LOADING]: 'Now Loading ...',
  [k.PX102]: '1154px',
  [k.FIXED]: 'fixed',
  [k._73]: '70%',
  [k._74]: '75%',
  [k.PX103]: '376px',
  [k.PX104]: '52px',
  [k.PX105]: '5px',
  [k._75]: '-5%',
  [k._76]: '-20%',
  [k.PX106]: '230px',
  [k.PX107]: '198px',
  [k.PX108]: '168px',
  [k._77]: '5%',
  [k.PUT_DOWN]: 'Put $19.99 down',
  [k.VOUCHER_RECEIVED]: '$319.99 voucher received',
  [k.FINAL_PAYMENT]: 'Final payment',
  [k.ESTIMATED_MAR]: 'Estimated Mar. 2021',
  [k.PRODUCT_SHIPPED]: 'Product shipped',
  [k.START_YOUR_ORKA_JOURNEY]: 'Start your Orka journey',
  [k.HOW_IT_WORKS]: 'How It Works?',
  [k.COMING_IN_MARCH]: 'Coming in March 2021.',
  [k.PUT_DOWN_NOW]: 'Put $19.99 down now',
  [k._78]: '?',
  [k.COMMON_QUESTIONS]: 'Common Questions',
  [k.SEE_MORE]: 'SEE MORE',
  [k._79]: '9%',
  [k._80]: '14%',
  [k._81]: '17%',
  [k.CAN_I_CANCEL_MY_PRE_ORDER]: 'Can I cancel my pre-order?',
  [k.HOW_DO_I_APPLY_THE_VOU]: 'How do I apply the $319.99 voucher?',
  [k.HOW_DOES_THE_DAY_MONEY_BACK]:
    'How does the 45-day money back guarantee work?',
  [k.HOW_MUCH_IS_ORKA_ONE_IF_I_JOIN]:
    'How much is Orka One if I join the Orka pre-order compaign?',
  [k.ARE_ORKA_ONE_HEARING_AIDS_FDA]:
    'Are Orka One hearing aids FDA-registered?',
  [k.IS_ORKA_ONE_THE_RIGHT_PRODUCT]: 'Is Orka One the right product for me?',
  [k.YES_THE_PRE_ORDER_DEPO]:
    'Yes, the $19.99 pre-order deposit is fully refundable. You can cancel your pre-order anytime.',
  [k.THE_VOUCHER_WILL_AUTOM]:
    'The $319.99 voucher will automatically be applied to your final payment of Orka One. Limit one (1) voucher per purchase.',
  [k.OUR_GOAL_IS_TO_MAKE_SURE_THAT]:
    "Our goal is to make sure that you have the best hearing experience with Orka One. If Orka One isn't for you, then start your return within 45 days of delivery, and ship the Orka One back to us for a full refund.",
  [k.THE_FULL_PAYMENT_OF_ORKA_ONE_I]:
    'The full payment of Orka One is $1180. If you have put $19.99 down, you will receive a $319.99 voucher that is automatically applied to your final payment.',
  [k.YES_ORKA_ONE_HEARING_AIDS_ARE]:
    'Yes. Orka One hearing aids are FDA-registered Class II 510K exempt medical devices designed for individuals 18 years old or older with mild to moderate hearing loss. Orka One is not personal amplification product (PSAPs).',
  [k.ORKA_ONE_IS_DESIGNED_TO_SERVE]:
    'Orka One is designed to serve people with mild to moderate hearing loss. [Am I a Candidate?](/AmICandidate)',
  [k.FINAL_PAYMENT_ESTIMATED_MAR]: 'Final payment(Estimated Mar. 2021)',
  [k.PRODUCT_SHIPPED_ESTIMATED_MAR]: 'Product shipped (Estimated Mar. 2021)',
  [k.COMING_IN]: 'Coming in',
  [k.MARCH1]: 'March 2021.',
  [k._82]: '$19.99',
  [k.PX_PX_PX_PX6]: '8px 16px 24px 40px',
  [k.DISABLE]: 'disable',
  [k._83]: '33%',
  [k.WHAT_S_NEXT]: "What's next?",
  [k.DAY_HOME_TRY_ON]: '45-Day Home Try-On',
  [k.ORKA_ONE_JOURNEY]: 'Orka One Journey',
  [k.WE_RE_PREPPING_YOUR_HEARING_AI]: `We're prepping your hearing aids to ensure  you enjoy a personsalized

          hearing experience. We'll let you know when they're on their
          way! (Won't be long!) Your`,
  [k.DAY_FREE_TRIAL]: '45-day free trial',
  [k.WILL_START_THE_DAY_A]: `will start the
          day after Orka hearing aids are delivered.`,
  [k.YOUR_OPINION_MATTERS_PLEASE_S]:
    'Your opinion matters. We would love to hear your thoughts on Orka One!',
  [k.AS_A_THANK_YOU_GIFT_WE_WILL_S]:
    " As a Thank you, get free hearing aid accessories (including domes and wax guards) to keep your Orka experience comfortable. Keep an eye for Orka emails so you don't miss out on any surprises.",
  [k._84]: '92%',
  [k._85]: '48%',
  [k.GUESTCHECKOUT]: 'GuestCheckout',
  [k.ALREADY_HAVE_AN_ACCOUNT]: 'Already have an account?',
  [k.LOG_IN1]: 'Log in',
  [k.HIDDEN]: 'hidden',
  [k.WHAT_S_YOUR_SHIPPING_ADDRESS]: "What's your shipping address?",
  [k.NEXT_CARD_VALIDATION]: 'NEXT: CARD VALIDATION',
  [k.CONTINUE_TO_PAYMENT]: 'Continue to Payment',
  [k.FIRSTNAME_REQUIRED_FIELD]: 'FirstName: Required field.',
  [k.LASTNAME_REQUIRED_FIELD]: 'LastName: Required field.',
  [k.GUEST_CHECKOUT]: 'Guest Checkout',
  [k.WE_NEED_YOUR_EMAIL_ADDRESS_TO]: `We need your email address to send your a receipt. You can also choose
        to create an account after checkout.`,
  [k.CONTINUE_TO_DELIVERY]: 'CONTINUE TO DELIVERY',
  [k.THIS_EMAIL_IS_ALREADY_TAKEN]: 'This Email Is Already Taken.',
  [k.PLEASE_LOG_IN_OR_USE_THIS_EMAI]:
    'Please log in or use this email as a guest.',
  [k.CONTINUE_AS_A_GUEST]: 'Continue As A Guest',
  [k.SIGN_IN]: 'SIGN IN',
  [k.VW5]: '600vw',
  [k.PX109]: '139px',
  [k._86]: '35%',
  [k.SIGN_IN_TO_ORKA]: 'Sign in to Orka',
  [k.TEXT]: 'text',
  [k.FORGOT_PASSWORD]: 'Forgot password?',
  [k.CHECK_OUT_AS_A_GUEST]: 'Check Out As A Guest',
  [k.PX110]: '257px',
  [k.EMPTY_EMAIL_ADDRESS_REQUIRED]: 'Empty email address: Required field.',
  [k.INVALID_EMAIL_ADDRESS1]: 'Invalid email address.',
  [k.MUST_BE_CHARACTERS_OR_MORE]: 'Must be 6 characters or more.',
  [k.YOU_VE_LOGGED_IN]: "You've logged in",
  [k.READY_TO_PLACE_YOUR_ORDER]: 'Ready to place your order?',
  [k.LET_S_MAKE_SURE_EVERYTHING_S_R]: "Let's make sure everything's right.",
  [k.PER_PAIR]: '$1180 per pair',
  [k._87]: '$1180',
  [k.DAY_MONEY_BACK_GUARANTEE1]: '45-Day Money Back Guarantee',
  [k.MONTH_LIMITED_WARRANTY]: '24-Month Limited Warranty',
  [k.VISA_CARD]: 'Visa Card',
  [k._88]: '-$',
  [k.GRAND_TOTAL]: 'Grand Total',
  [k._89]: '$',
  [k.PLACE_YOUR_FREE_ORDER]: 'Place your free order',
  [k.PLACE_YOUR_ORDER]: 'Place your order',
  [k.BY_PLACING_YOUR_ORDER_YOU_AGR]:
    "By placing your order, you agree to Orka's",
  [k.PRIVACY_POLICY1]: 'Privacy Policy.',
  [k.PX111]: '291px',
  [k.PX112]: '265px',
  [k.PX113]: '16.8px',
  [k.PX_SOLID_F1]: '1.5px solid #F03738',
  [k.WE_RE_SORRY_WE_COULDN_T_COMPL]:
    "We're sorry, we couldn't complete your purchase using the payment method you selected. Please add another card.",
  [k.THE_EXPECTED_TIME_OF_DEPARTURE]: 'The expected time of departure is',
  [k.AND_THE]: `and the
                          shipping usually takes 2-7 business days.`,
  [k.ORKA_ONE_DAY_FREE_HOME_TRY]: 'Orka One 45-Day Free Home Try-On',
  [k.APPLY]: 'Apply',
  [k.NEXT_STEP]: 'NEXT STEP',
  [k.NEXT_STEP_IT_S_FREE]: "Next STEP (IT'S FREE)",
  [k.PX114]: '195px',
  [k.PX115]: '108px',
  [k.PX116]: '234px',
  [k.PX117]: '117px',
  [k.MIDDLE]: 'middle',
  [k.ADD_CARD_INFO_TO_CONFIRM_YOUR]: 'Add card info to confirm your free trial',
  [k.WE_REQUIRE_A_VALID_CREDIT_OR_D]: `We require a valid credit for your order. You may

                  see a $1 charge, but not to worry - the free try-on is free!
                  It'll be removed and you won't be charged.`,
  [k.PX118]: '6.18px',
  [k.YOUR_HOME_TRY_ON_IS_IN_PROCESS]: 'Your Home Try-On is in process!',
  [k.PAYMENT_COMPLETED]: 'Payment Completed',
  [k.PURCHASE_SUCCESSFUL]: 'Purchase Successful !',
  [k.YOUR_ORDER_CONFIRMATION_HAS_BE]:
    'Your order confirmation has been sent to',
  [k.NOTE]: 'Note:',
  [k.WE_RE_PREPPING_YOUR_HEARING_AI1]: `We're prepping your hearing aids, and we will let you know when
                they're on their way. (Not too long from now!)`,
  [k.THANK_YOU_FOR_COMPLETING_THE_O]: `Thank you for being part of Orka Free Try-On and having us wih you on your hearing journey.`,
  [k.PLEASE_REMEMBER_THAT_ORKA_SUPP]: `Please remember that Orka support team is always here to help. You will never be alone.`,
  [k.VIEW_MY_ORDER_WITH_ACCOUNT]: 'view-my-order-with-account',
  [k.H3]: 'h1',
  [k.DON_T_HAVE_AN_ACCOUNT_WITH_US]: "Don't have an account with us yet?",
  [k.THIS_ACCOUNT_WILL_BE_USED_TO_A]: `This account will be used to access your order information and
                  hearing profile on Orka's website and the Orka Health app. Make
                  sure you remember the account information for future use.`,
  [k.SET_YOUR_PASSWORD_BELOW]: 'Set Your Password Below',
  [k.END]: 'end',
  [k.TOGGLE_PASSWORD_VISIBILITY]: 'toggle password visibility',
  [k.LARGE]: 'large',
  [k.CONFIRM_PASSWORD]: 'Confirm Password',
  [k.CONFIRMPASSWORD]: 'confirmPassword',
  [k.CONFIRM_PASSWORD_MUST_BE_CH]:
    'Confirm Password: Must be 6 characters or more.',
  [k.TOGGLE_CONFIRM_PASSWORD_VISIBI]: 'toggle confirm password visibility',
  [k.CONFIRM_PASSWORD1]: 'confirm password',
  [k.YOUR_ACCOUNT_WILL_BE_CREATED_W]:
    'Your account will be created with the email:',
  [k.TERMS_CONDITIONS]: 'terms_conditions',
  [k.BY_CREATING_AN_ACCOUNT_I_AGRE]:
    "By creating an account, I agree with Orka's",
  [k.NOOPENER_NOREFERRER]: 'noopener noreferrer',
  [k.OVERLINE]: 'overline',
  [k.ORKA_S_TERMS_OF_SERVICE]: 'Terms of Service',
  [k.WARNING_CIRCLE]: 'warning-circle',
  [k.CREATE_ACCOUNT]: 'create-account',
  [k.CREATE_ACCOUNT1]: 'CREATE ACCOUNT',
  [k.PX_PX13]: '32px 40px',
  [k.PX_PX14]: '12px 24px',
  [k.CONFIRM_PASSWORD_REQUIRED_FIE]: 'Confirm password: Required field.',
  [k.PLEASE_AGREE_TO_OUR_TERMS_OF_S1]: 'Please agree to our terms of services.',
  [k.THE_PASSWORDS_DO_NOT_MATCH_PL]:
    'The passwords do not match. Please try again.',
  [k.MY_BILLING_ADDRESS_IS_THE_SAME]:
    'My billing address is the same as my shipping address.',
  [k.SUBTITLE1]: 'subtitle1',
  [k.CARD_INFORMATION]: 'Card Information',
  [k.BODY1]: 'body2',
  [k.CARD1]: 'Card',
  [k.YOU_WILL_NOT_BECHARGED_UNTIL_Y]: `You will not be charged until you confirm your order on the next
              screen.`,
  [k.CAPTION]: 'caption',
  [k.PX119]: '119px',
  [k.PX_PX15]: '16px 19px',
  [k._90]: '#424770',
  [k.AAB_C]: '#aab7c4',
  [k.E]: '#9e2146',
  [k.ACTIVE]: 'active',
  [k.WAS_THIS_ARTICLE_HELPFUL]: 'Was this article helpful?',
  [k.OUT_OF_FOUND_THIS_HELP]: '298 out of 338 found this helpful',
  [k.YES2]: 'YES',
  [k.HAVE_MORE_QUESTIONS_CHAT_WITH]:
    'Have more questions? Chat with us online.',
  [k.PRE_ORDER1]: 'Pre-order',
  [k.ORKA_ONE1]: ' Orka One ',
  [k.NOW_FOR_SHIPPING_IN_FEBRUARY]: 'Now for Shipping in February.',
  [k.EARLY_BIRDS_DISCOUNT_AVAILABLE]: 'Early birds discount available now',
  [k.PRE_ORDER_NOW]: 'Pre-Order Now',
  [k.PX120]: '75px',
  [k.CES_INNOVATION_AWARDS]: 'CES*2021 Innovation Awards',
  [k.CONTENT]: '-content',
  [k.HEADER]: '-header',
  [k.COMING_SOON]: 'COMING SOON',
  [k.INSIDERCHECKOUT]: '/Checkout',
  [k.TECHSPECS]: 'techspecs-',
  [k.WHO_SHOULD_USE_ORKA_ONE_HEARIN]: 'Who should use Orka One hearing aids?',
  [k.CAN_I_APPLY_FOR_A_REFUND]: 'Can I apply for a refund?',
  [k.STAY_TUNED]: 'Stay Tuned',
  [k.FOR_MORE_UPDATES]: 'For More Updates',
  [k.SUBSCRIBE_TO_STAY_UPDATED_WITH]: `Subscribe to stay updated with special offers, discounts or
              product news!`,
  [k.SIGN_UP]: 'Sign Up',
  [k.BEFORE_CONTINUING_PLEASE_ENSU2]: `Before continuing, please ensure you have read and accepted
              Orka's`,
  [k.TERMS_OF_SERVICE2]: 'Terms of service',
  [k.ORKA_RESPECTS_YOUR_PRIVACY2]: `. Orka respects your privacy, and will keep your data safe and
              secure.`,
  [k.YOU_HAVE_SUCCESSFULLY_SUBSCRIB]: `You have successfully subscribed to Orka! A welcome email will be
            sent to`,
  [k.SOON]: 'soon.',
  [k.FOLLOW_US_ON_SOCIAL_MEDIA]: 'Follow us on social media:',
  [k.REM22]: '2rem',
  [k.S1]: '1s',
  [k.REM23]: '0.5rem',
  [k.HTTPS_WWW_FACEBOOK_COM_ORKA]:
    'https://www.facebook.com/Orka-110016237448210/',
  [k.HTTPS_ORKA_MEDIUM_COM]: 'https://orka.medium.com/',
  [k.HTTPS_TWITTER_COM_ORKAHEALTH]: 'https://twitter.com/OrkaHealth',
  [k.SUBSCRIBE_TO_STAY_UPDATED_WITH1]: `Subscribe to stay updated with special offers, discounts or
                product news!`,
  [k.YOU_HAVE_SUCCESSFULLY_SUBSCRIB1]: `You have successfully subscribed to Orka! A welcome email will be
              sent to`,
  [k.REM24]: '1.75rem',
  [k.PUT_DOWN_NOW1]: 'Put $19.99 Down Now',
  [k.GET_OFF_YOUR_FINAL_PAY]: 'Get $319.99 off your final payment',
  [k.NOTIFY_ME]: 'NOTIFY ME',
  [k.IMAGE_JPEG]: 'image/jpeg',
  [k.ORKA_ONE_SLEEK_DESIGN_HEARING]: 'Orka One sleek design hearing aids',
  [k.MINS_FAST_CHARGING_HEARING]: '30 mins fast charging hearing aids',
  [k.UP_TO_HOURS_CONTINUOUS_HEAR]: 'up to 35 hours continuous hearing Orka One',
  [k.WATERPROOF_CASE_DESIGN_HEARING]: 'waterproof case design hearing aids',
  [k.SRC_IMGS_PRODUCT_ORKA_ONE]: '/Product/orka_one_',
  [k.PRODUCT_PICTURE]: 'product_picture_',
  [k.REM25]: '0.25rem',
  [k.PX_SOLID1]: '1px solid #000000',
  [k.BUY_NOW]: 'Buy Now',
  [k.FREE_HOME_TRY_ON1]: 'FREE HOME TRY-ON',
  [k.CHAT_WITH_US_ONLINE3]: 'Chat with us online',
  [k.SUPPORTED_PAYMENT_TYPES]: 'Supported payment methods:',
  [k.PX121]: '10px 0',
  [k.REM_REM]: '1.5rem 1.5rem',
  [k.PX_PX_PX4]: '16px 0px 8px',
  [k.PX_PX16]: '16px 0px',
  [k.PX122]: '26px 0',
  [k.PX_PX_PX5]: '6px 0px 0px',
  [k.PX123]: '45px',
  [k.VH8]: '70vh',
  [k.PX124]: '320px',
  [k._91]: '42%',
  [k.FFE_A]: '#FFE1A3',
  [k.BOLD]: 'bold',
  [k.REM26]: '0rem',
  [k.PX125]: '21px',
  [k.DAY_MONEY_BACK_GUARANTEE2]: '45-day money back guarantee',
  [k.REPLACEMENT_GUARANTEE_UNDER_WA]: 'Replacement guarantee under warranty',
  [k.REMOTE_AUDIOLOGY_SERVICES]: 'Remote audiology services',
  [k.AI_HEARING_AIDS1]: 'AI Hearing Aids',
  [k.PER_PAIR1]: 'Per pair',
  [k.PER_PAIR2]: 'Per Pair',
  [k.THE_ACTUAL_HUMAN_CENTERED_HEAR]:
    'The real customer-centered design, with cutting-edge technologies. Bringing you the ultimate hearing experience at your fingertips.',
  [k.WIRELESS_CONNECTION]: 'Wireless Connection',
  [k.AI_DENOISE_TECHNOLOGY]: 'AI DeNoise Technology',
  [k.FDA_REGISTERED_CLASS_II_MEDICA]: 'FDA-registered Class II Medical Device',
  [k.INSIDERCHECKOUT_INSIDER]: '/Checkout?Insider',
  [k.MAKE_THE_MOST_OF_ORKA_JOURNEY]: 'Make the most of Orka journey',
  [k.DAY_MONEY_BACK_GUARANTEE3]: '45-day Money-back Guarantee',
  [k.MONTH_LIMITED_WARRANTY1]: '24-month Limited Warranty',
  [k.FREE_SHIPPING_FREE_RETURNS]: 'Free Shipping & Free Returns',
  [k.GET_FULL_REFUND_BACK_ANYTIME_W]:
    'Get a full refund anytime within the 45-day trial period, no obligation, no questions asked.',
  [k.YOU_WILL_RECEIVE_A_BRAND_NEW_P]:
    'You will receive a brand new product if there is any damage (not intentionally) to the current one.  ',
  [k.MAKE_THE_MOST_OF_THE_PRODUCT_B]:
    'Worry free with free shipping and returns on all your orders',
  [k.ORKA_DAYS_TRIAL]: 'orka_45days_trial',
  [k.ORKA_WARRANTY]: 'orka_warranty',
  [k.ORKA_REFUND]: 'orka_refund',
  [k.WOMAN_WEARING_ORKA_WITH_BLUETO]:
    'woman wearing Orka with Bluetooth connected',
  [k.CONVERSATIONS_ARE_ABOUT_TO_GET]:
    'Conversations are About to Get Even Better.',
  [k.BUY_NOW1]: 'buy now-$1180',
  [k.TECH_SPECS]: 'Tech specs',
  [k.SEE_ALL_FAQS]: 'See All FAQs',
  [k.IN_THE_BOX]: 'In The Box',
  [k.ORKA_ONE_CHARGING_CASE]: 'Orka One charging case',
  [k.SILICONE_EAR_TIPS_WITH_SIZE]:
    'Silicone ear tips with 3 size sections (Each size with 2 form sections)',
  [k.TYPE_C_CONNECTOR]: 'Type-C connector',
  [k.USER_QUICK_START_GUIDE]: 'User quick start guide',
  [k.COMPATIBILITY]: 'Compatibility',
  [k.COMPATIBLE_WITH_ANY_BLUETOOTH]:
    'Compatible with any Bluetooth device, including iOS, Android, tablets, TV, and laptops',
  [k.PX126]: '96px',
  [k.SIZE_AND_WEIGHT]: 'Size and weight',
  [k.OPERATING_SYSTEM]: 'Operating system',
  [k.AUDIO_TECHNOLOGY]: 'Audio technology',
  [k.SENSORS]: 'Sensors',
  [k.CONTROLS]: 'Controls',
  [k.SWEAT_AND_WATER_RESISTANT]: 'Sweat and water resistant',
  [k.BATTERY]: 'Battery',
  [k.CHARGING_CASE1]: 'Charging case',
  [k.CONNECTIVITY]: 'Connectivity',
  [k.MENTIONS_REVIEWS]: 'MENTIONS & REVIEWS',
  [k.HEARING_AIDS_ARE_NOT_NEW_TECH]: `"Hearing aids are not new technology, of course. But hearing aids that
          integrate AI technology to improve speech recognition and filter out
          background noise are, and the Orka One is leading the way."`,
  [k.MAKEUSEOF]: '- MakeUseOf',
  [k.PLACEHOLDER]: 'placeHolder',
  [k.CES_INNOVATION_AWARD_HON]: '"CES 2021 Innovation Award Honoree . "',
  [k.ORKA_ONE_IS_THE_FIRST_HEARING]: `"Orka One is the first hearing aids to use AI-DeNoise
                technology. "`,
  [k.ORKA_TEAM_IS_COMMITTED_TO_PRO]: `"Orka Team is committed to producing human-centered health and
                medical device. "`,
  [k.THE_ORKA_AWARDS]: 'The Orka Awards',
  [k.CUTTING_EDGE_TECHNOLOGIES_MEET]: `Cutting-edge technologies meet the award-winning product design, and
          we don't stop here.`,
  [k.CES]: 'CES 2021',
  [k.INNOVATION_AWARDS]: 'Innovation Awards',
  [k.HONOREE]: 'Honoree',
  [k.PX127]: '78px',
  [k.PX_PX17]: '25px 10px',
  [k.MEET_OUR_ORKA_ONE]: 'Meet our Orka One',
  [k.PANEL]: 'panel1',
  [k.AN_OVER_THE_AIR_SERVICE_ON_DEM]: 'An over-the-air service on demand',
  [k.EXPERIENCE_FULL_REMOTECARE_CYC]: `Experience full RemoteCare cycle on Orka Health app. Get Orka's
            professional consultation, fitting, and follow-up service at home,
            on vacation, or anywhere you want.`,
  [k.PANEL1]: 'panel2',
  [k.JUST_AN_APP_AND_NO_MORE_TOOLS]: 'Just an app and no more tools needed',
  [k.ORKA_MAKES_OVER_THE_AIR_FITTIN]: `Orka makes over the air fitting come true. Simply download Orka
            Health, meet with Orka team online, and we will tune Orka One till
            it meets what you just need.`,
  [k.PANEL2]: 'panel3',
  [k.MINIMAL_DELAY_IN_REAL_TIME_TUN]: 'Minimal delay in Real-time tuning',
  [k.OUR_INNOVATIVE_AND_DATA_DRIVEN]: `Our innovative and data-driven fitting solutions enable Orka's
            experienced hearing care professionals tune your Orka One remotely,
            with no delay.`,
  [k.PANEL3]: 'panel4',
  [k.UNLIMITED_FOLLOW_UPS]: 'Unlimited follow-ups',
  [k.NEED_HELP_WHEN_CONSIDERING_NEW]: `Need help when considering new hearing device or after fitting? An
            appointment with our consultants is always an option! Orka provides
            a remote while seamless care throughout your journey with us.`,
  [k.PANEL4]: 'panel5',
  [k.TRUSTED_HEARING_AID_PROFESSION]:
    'Trusted hearing aid professionals from Lloyds',
  [k.FOR_OVER_YEARS_LLOYDS_HAS]: `For over 45 years, Lloyds has helped people enjoy a better quality
            of life by solving their hearing problems. You are in good hands
            with our audiology professionals from home.`,
  [k.DISCOVERING_THE_BENEFITS]: 'Discovering the benefits',
  [k.START_THE_BENEFITS_FROM_TODAY]: 'Start the benefits from today!',
  [k.SCHEDULE_FREE_PHONE_CONSULTATI]: 'Schedule Free Phone Consultation',
  [k.COMMON_QUESTIONS1]: 'Common Questions?',
  [k.REMOTE_CARE]: 'remote-care-',
  [k.CAN_I_BOOK_ORKA_S_FREE_CONSULT]:
    "Can I book Orka's free consultation without purchasing your product?",
  [k.WHAT_HAPPENS_IF_I_NEED_TO_RESC]:
    'What happens if I need to reschedule or cancel my appointment?',
  [k.WHAT_DO_YOU_MEAN_BY_UNLIMITED]:
    'What do you mean by unlimited follow-ups?',
  [k.DO_I_NEED_A_CLINIC_VISIT_BEFOR]:
    'Do I need a clinic visit before/after remote fitting?',
  [k.NO_HIDDEN_FEES]: 'No hidden fees',
  [k.UNLIMITED_CONVERSATIONS]: 'Unlimited Conversations',
  [k.ANY_TIME_ANY_MATTER]: 'Any time, any matter',
  [k.SCHEDULE1]: 'SCHEDULE',
  [k.PX_PX_PX_RGBA]: '0px 24px 64px rgba(55, 44, 21, 0.05)',
  [k.PX128]: '630px',
  [k._92]: '93%',
  [k.JUMPING]: 'Jumping...',
  [k.VISIBLE]: 'visible',
  [k.NEW_TO_ORKA]: 'New to Orka?',
  [k.CREATE_ACCOUNT2]: 'Create account',
  [k.PX129]: '-2px',
  [k.WRONG_PASSWORD_TRY_AGAIN_OR_C]:
    'Wrong password. Try again or contact our support team to reset it.',
  [k.COULDN_T_FIND_YOUR_ORKA_ACCOUN]: "Couldn't find your Orka Account.",
  [k.LET_US_KNOW_MORE_ABOUT_YOU]: 'Let us know more about you',
  [k.REFERENCE_INFORMATION_REQUIRED]: `Reference information required for accurate hearing counselling
        services.`,
  [k.SUBSCRIBE_TO_STAY_UPDATE_WITH]:
    'Subscribe to stay update with discounts and sales!',
  [k.LATER_TO_EDIT]: 'Later To Edit',
  [k.FIRST_NAME_REQUIRED_FIELD]: 'First name: Required field.',
  [k.LAST_NAME_REQUIRED_FIELD]: 'Last name: Required field.',
  [k.DATE_INFORMATION_REQUIRED_FIE]: 'Date information: Required field.',
  [k.GENDER_INFORMATION_REQUIRED_F]: 'Gender information: Required field.',
  [k.RESIDENCY_INFORMATION_REQUIRE]: 'Residency information: Required field.',
  [k.PLEASE]: 'Please',
  [k.SET]: 'set',
  [k.RESET]: 'reset',
  [k.YOUR_PASSWORD]: 'your password',
  [k.BACK_TO]: 'Back to',
  [k.SIGN_IN1]: 'Sign in',
  [k.CONFIRM1]: 'Confirm',
  [k._93]: '#666666',
  [k.RESET_YOUR_PASSWORD]: 'Reset Your Password',
  [k.WE_LL_SEND_YOU_AN_EMAIL_WITH_I]: `We'll send you an email with instructions on how to create a new
          password.`,
  [k.SEND]: 'SEND',
  [k.CREATE_AN_ACCOUNT]: 'Create an account',
  [k.TERMS_OF_SERVICE3]: 'Terms of Service.',
  [k.PX130]: '19px',
  [k.EMAIL_ADDRESS_ALREADY_EXISTS]: 'Email address already exists.',
  [k.ACCOUNT_CREATED_SUCCESSFULLY]: 'Account created successfully',
  [k.FIREBASE]: 'firebase',
  [k.WE_HAVE_UPDATED_LOGIN_SYSTEM]: 'We have updated login system',
  [k.ACCOUNT_NEEDED_TO_BE_VERIFIED]: 'Account needed to be verified',
  [k.PLEASE_CHECK_YOUR_EMAIL_TO]: 'Please check your email to',
  [k.RESET_YOUR_ORKA_PASSWORD]: 'reset your Orka password.',
  [k.ACTIVATE_YOUR_ORKA_ACCOUNT]: 'activate your Orka account.',
  [k.WE_JUST_SENT_A_MAGIC_LINK_TO]: 'We just sent a magic link to',
  [k.DIDN_T_RECEIVE_AN_EMAIL]: "Didn't Receive An Email?",
  [k._94]: '##636363',
  [k.EMAIL_SENT]: 'Email Sent',
  [k.FOOTER_SIGNUP]: 'footer-signup',
  [k.SUBMIT2]: 'submit',
  [k.THANK_YOU_FOR_JOINING_US]: 'Thank you for joining us!',
  [k.CHAT_NOW]: 'Chat Now',
  [k.EMAIL_US]: 'Email Us',
  [k.MAILTO_CA_SUPPORT_HIORKA_COM]: 'mailto:support@hiorka.com',
  [k.FOLLOW_US]: 'Follow Us',
  [k._95]: '95%',
  [k.PRODUCT_SERVICE]: 'Product & Service',
  [k.ABOUT]: 'About',
  [k.ABOUT_US]: 'About Us',
  [k.HEARING_BLOG]: 'Hearing Blog',
  [k.SUPPORT]: 'Support',
  [k.FAQ]: 'FAQ',
  [k.ORDER_STATUS]: 'Order Status',
  [k.ABOUT_US1]: 'About us',
  [k.LABOR_DAY_SPECIAL_OFFER]: 'Labor Day Special Offer.',
  [k.SIGN_UP_TO_ORKA_S_EMAIL_LIST_A]:
    "Sign up to Orka's email list and get your $350 coupon today.",
  [k.ENJOY_ON_US]: 'Enjoy $350 on us.',
  [k.DON_T_MISS_OUT_YOUR_BEST_HEARI]:
    "Don't miss out your best hearing aids in your life.",
  [k.I_WANT_IT]: 'I WANT IT',
  [k.NO_THANKS]: 'No,Thanks',
  [k.AWARD_WINNING]: 'Award-Winning.',
  [k.NO_OBLIGATION]: 'No Obligation.',
  [k.FREE_TRY_ON1]: 'Free Try-On.',
  [k.WE_ARE_WORKING_ON_SOME_EXCITIN]:
    'We know getting new hearing aids can be a commitment, that is why we want you to try it first completely worry-free! 3 Weeks of Award-Winning, Premium Hearing Aids, no obligation, no additional charges.',
  [k.TRY_NOW_PLAY_LATER]: 'Try Now, Pay Later',
  [k.FREE_TRY_ON_SUB_TITLE]:
    'Finding the right hearing aid can be challenging and a personal decision. Our hassle-free program  lets you try out the devices completely free with the support of hearing experts for 45 Days.',
  [k.ONE_LAST_STEP_TO_YOUR_FREE_TRY]: 'One Last Step to Your Free Try-On!',
  [k.ORKA_HEARING_AIDS_ARE_TAILORED]:
    'Orka Hearing Aids are tailored for individuals 18 or older with perceived mild to moderately-severe hearing loss. Complete a short survey to see if you are a candidate.',
  [k.LEAVE_US_YOUR_EMAIL_TO_START]: 'Leave us your email to start',
  [k.I_WANT_TO_EXPLORE_ORKA_ONE_FIR]: 'I want to explore Orka One first',
  [k.PX131]: '205px',
  [k._96]: '68%',
  [k.HOME_FIT_HEARING_TEST_WITH_ORK]:
    'Home-Fit Hearing Screening with Orka Health',
  [k.TEST_YOUR_HEARING_AND_SYNC_YOU]:
    'Test your hearing and sync your profile within the ORKA Health App',
  [k.HOW_DOES_IT_WORK]: 'How does it work?',
  [k.IN_SITU_AUDIOMETRY_SIMPLY_AND]:
    'In-App Audiometry, Simply and Convenience',
  [k.TEST_AT_HOME]: 'Test at Home',
  [k.NO_MORE_LONG_DRIVES_TO_CLINICS]: `No more long drives to clinics. No more waiting in line. Using Orka
            Hearing Aids for thr screening, all you need is 10 minutes and a quiet
            place at home. Results will be ready immediately.`,
  [k.TAILORED_HEARING_PROFILE]: 'Tailored Hearing Profile',
  [k.OUR_MEDICAL_GRADE_HEARING_TEST]: `Our medical-grade hearing screening will provide accurate hearing
            results. Your hearing aids will then be calibrated in real-time to
            fit your hearing for immediate use.`,
  [k.NO_MORE_LONG_DRIVES_TO_CLINICS1]: `No more long drives to clinics. No more waiting in line. Using Orka
          Hearing Aids for thr test, all you need is 10 minutes and a quiet
          place at home. Results will be ready immediately.`,
  [k.OUR_MEDICAL_GRADE_HEARING_TEST1]: `Our medical-grade hearing screening will provide accurate hearing results.
          Your hearing aids will then be calibrated in real-time to fit your
          hearing for immediate use.`,
  [k.WHEN_DO_I_NEED_AN_HOME_FIT_HEA]:
    'When do I need an Home-Fit hearing screening?',
  [k.YOU_DON_T_HAVE_AN_AUDIOGRAM]:
    " You don't have an audiogram (hearing screening) ready for fitting.",
  [k.YOUR_CURRENT_HEARING_AIDS_N]: `Your current hearing aids need to be adjusted due to hearing loss
            changes.`,
  [k.A_CLINICAL_HEARING_TEST_IS]: `A clinical hearing screening is inconvenient because of COVID or
            distance.`,
  [k.ACCURATE_WITH_DB]: 'Accurate with 5dB',
  [k.OUR_MEDICAL_GRADE_HEARING_TEST2]: `Our medical-grade hearing screening is an algorithm calibrated hearing
              test. It will assess each of your ears' hearing levels accurately
              within`,
  [k.DB_DECIBELS]: '5 dB (decibels).',
  [k.A_BROAD_RANGE_OF_FREQUENCIES]: 'A Broad Range of Frequencies',
  [k.WE_WILL_TEST_YOUR_ABILITY_TO_H]:
    'We will test your ability to hear different frequencies between',
  [k.HZ_AND_HZ]: '250Hz and 8000Hz',
  [k.TO_CREATE_A_GRAPHICAL_HEARING]: `to create a graphical hearing profile, allowing you to better
              visualize and understand your hearing.`,
  [k.BACK_TO_INSIDER_COUPON_CODE]: 'Back To Top',
  [k.PX_PX18]: '63px 20px',
  [k.PX132]: '61.5px',
  [k.PX133]: '-3.6px',
  [k.PX134]: '-0.5px',
  [k.PX135]: '-1.8px',
  [k.COPY_TO_CLIPBORAD]: 'copy to clipborad',
  [k.COPIED]: 'copied',
  [k.COPY_THE_INSIDER_CODE]: 'Copy the Insider Code',
  [k.COPIED1]: 'Copied',
  [k.COPY_THE_CODE_ABOVE_AND_PASTE]:
    'Copy the code above and paste it at check out.',
  [k.CHECK_OUT_NOW]: 'CHECK OUT NOW',
  [k.CONGRATS]: 'Congrats!',
  [k.CONGRATS_YOU_RE_QUALIFIED_GE]:
    'You’re qualified. Get your insider code now!',
  [k.Begin_Your_Free_Try_On]: 'Begin Your Free Try-On with this Insider Code!',
  [k.DON_T_WORRY_IF_YOU_DON_T_HAVE]: `Don’t worry if you don’t have an audiogram. You can obtain one from the
        Orka Health app.`,
  [k.GET_CODE_NOW]: 'GET CODE NOW',
  [k.LEARN_MORE_ABOUT_ORKA_HEALTH]: 'Learn More About Orka Health',
  [k.DISCOVER_ORKA_ONE_FIRST]: 'Discover Orka One First',
  [k.PX_PX_PX6]: '30px 100px 0px',
  [k._97]: '43%',
  [k.PX136]: '319px',
  [k.PX137]: '183px',
  [k._98]: '137%',
  [k.PX138]: '-1.4px',
  [k.PX139]: '-0.2px',
  [k.PX140]: '-0.24px',
  [k.PX141]: '-0.4px',
  [k.PX142]: '-0.1px',
  [k.PX143]: '687px',
  [k.PX144]: '1374px',
  [k.PX145]: '141px',
  [k.GET_ME_STARTED]: 'GET ME STARTED',
  [k.I_WANT_TO_EXPLORE_MORE_ABOUT_O]:
    'I want to explore more about Orka One first',
  [k.SCHEDULE_NOW2]: 'schedule now',
  [k.YOU_ARE_ALL_SET]: 'You are all set!',
  [k.THANK_YOU_FOR_SIGNING_UP]: 'Thank you for signing up!',
  [k.STRING]: 'string',
  [k.VH9]: '40vh',
  [k.VH10]: '10vh',
  [k.STAY_TUNED_FOR_MORE_UPDATES]: 'Stay Tuned For More Updates',
  [k.BALL]: 'ball',
  [k.YOUR_EMAIL]: 'Your Email',
  [k.I_AGREE_TO_THE_ORKA_S1]: "I agree to the Orka's",
  [k.SIGN_UP1]: 'SIGN UP',
  [k.CHAT_WITH_US]: 'Chat with us',
  [k.BUNDLE_CONFIRMED]: 'bundle-confirmed',
  [k.MEET_THE_ORKA_BUNDLE]: 'Meet The Orka Bundle',
  [k.A_PAIR_OF_HEARING_AIDS_PLUS_ON]:
    'A pair of hearing aids plus on-going care, for only $1180',
  [k.AI_DENOISE_HEARING_AIDS]: 'AI DeNoise Hearing Aids',
  [k.THE_ACTUAL_HUMAN_CENTERED_HEAR1]:
    'The real customer-centered design, with cutting-edge technologies. Bringing you the ultimate hearing experience at your fingertips.',
  [k.EXPLORE_MORE]: 'Explore More',
  [k.RECEIVE_REAL_TIME_ADJUSTMENTS]: `Receive real-time adjustments on your Orka One and stay in contact
            with Orka hearing professionals, all can be done on Orka Health app.`,
  [k.COLOUMN]: 'coloumn',
  [k.PX146]: '148px',
  [k.CENTER1]: 'center ',
  [k.PX_PX19]: '12px 56px',
  [k.PX_PX20]: '24px 40px',
  [k.PX147]: '152px',
  [k.PX148]: '540px',
  [k.PX149]: '95px',
  [k.PX150]: '280px',
  [k.PX151]: '620px',
  [k.PX152]: '181px',
  [k._99]: '*',
  [k.I_CARE_ABOUT_HOW_WELL_I_CAN]: `① I care about how well I can understand a conversation in background
        noise.`,
  [k.I_CARE_IF_THIS_HEARING_SOLUT]: `② I care if this hearing solution is compatible with my phone, tablet,
        TV, or computer.`,
  [k.I_CARE_ABOUT_HOW_WELL_A_HEAR]: `③ I care about how well a hearing solution can connect with my phone,
        tablet, TV, or computer.`,
  [k.SPEECH_IN_NOISE]: 'Speech in noise',
  [k.I_CARE_ABOUT_HOW_WELL_I_CAN_UN]:
    'I care about how well I can understand a conversation in background noise.',
  [k.LAYOUT]: '_layout',
  [k.CONTENT1]: '_content',
  [k._100]: '①',
  [k.IMG]: '_img',
  [k.I_CARE_IF_THIS_HEARING_SOLUTIO]:
    'I care if this hearing solution is compatible with my phone, tablet, TV, or computer.',
  [k._101]: '②',
  [k.I_CARE_ABOUT_HOW_WELL_A_HEARIN]:
    'I care about how well a hearing solution can connect with my phone, tablet, TV, or computer.',
  [k._102]: '③',
  [k.PX153]: '260px',
  [k.I_M_NOT_SURE]: "I'M NOT SURE",
  [k.BOX]: 'box',
  [k.PX154]: '1100px',
  [k.SPACE_AROUND]: 'space-around',
  [k.SUBMIT_CONSULTATION_SUCCESSFUL]: 'Submit consultation successfully',
  [k.TRY_ORKA_ONE_WITH_DAY]:
    'Try Orka One with 45-day 100% money back guarantee!',
  [k.SHOP_NOW]: 'SHOP NOW',
  [k.YOUR_HEARING_CONSULTATION_IS_S1]:
    'Your hearing consultation is scheduled!',
  [k.AN_ORKA_HEARING_SPECIALIST_WIL]: `An Orka hearing specialist will call you at the number you have
              submitted.`,
  [k.SURVEY]: 'Survey',
  [k.F_AA_A]: '#F9AA1A',
  [k.SUNDAY]: 'Sunday',
  [k.MONDAY]: 'Monday',
  [k.TUESDAY]: 'Tuesday',
  [k.WEDNESDAY]: 'Wednesday',
  [k.THURSDAY]: 'Thursday',
  [k.FRIDAY]: 'Friday',
  [k.SATURDAY]: 'Saturday',
  [k.MOBILE_CALENDAR]: 'mobile-calendar',
  [k.INPUT]: 'input',
  [k.RESULT_YOUNGER]: 'result_younger',
  [k.NOTUSA]: 'notUSA',
  [k.SUCCESSFUL]: 'successful',
  [k.RESULT_ADULT]: 'result_adult',
  [k.NOHISTORY]: 'noHistory',
  [k.SLIDEBACK_APPEAR]: 'slideBack-appear',
  [k.SLIDEBACK_APPEAR_ACTIVE]: 'slideBack-appear-active',
  [k.SLIDEBACK_ENTER]: 'slideBack-enter',
  [k.SLIDEBACK_ENTER_ACTIVE]: 'slideBack-enter-active',
  [k.SLIDEBACK_ENTER_DONE]: 'slideBack-enter-done',
  [k.SLIDEBACK_EXIT]: 'slideBack-exit',
  [k.SLIDEBACK_EXIT_ACTIVE]: 'slideBack-exit-active',
  [k.SLIDEBACK_EXIT_DONE]: 'slideBack-exit-done',
  [k.SLIDE_APPEAR]: 'slide-appear',
  [k.SLIDE_APPEAR_ACTIVE]: 'slide-appear-active',
  [k.SLIDE_ENTER]: 'slide-enter',
  [k.SLIDE_ENTER_ACTIVE]: 'slide-enter-active',
  [k.SLIDE_ENTER_DONE]: 'slide-enter-done',
  [k.SLIDE_EXIT]: 'slide-exit',
  [k.SLIDE_EXIT_ACTIVE]: 'slide-exit-active',
  [k.SLIDE_EXIT_DONE]: 'slide-exit-done',
  [k.GRID]: ' grid',
  [k.PX155]: '42px 0 0',
  [k.PX156]: '55px 0 0',
  [k.SCHEDULE_YOUR_FREE_CONSULTATIO]: 'Schedule your free consultation',
  [k.CARDBACK_APPEAR]: 'cardBack-appear',
  [k.CARDBACK_APPEAR_ACTIVE]: 'cardBack-appear-active',
  [k.CARDBACK_ENTER]: 'cardBack-enter',
  [k.CARDBACK_ENTER_ACTIVE]: 'cardBack-enter-active',
  [k.CARDBACK_ENTER_DONE]: 'cardBack-enter-done',
  [k.CARDBACK_EXIT]: 'cardBack-exit',
  [k.CARDBACK_EXIT_ACTIVE]: 'cardBack-exit-active',
  [k.CARDBACK_EXIT_DONE]: 'cardBack-exit-done',
  [k.CARD_APPEAR]: 'card-appear',
  [k.CARD_APPEAR_ACTIVE]: 'card-appear-active',
  [k.CARD_ENTER]: 'card-enter',
  [k.CARD_ENTER_ACTIVE]: 'card-enter-active',
  [k.CARD_ENTER_DONE]: 'card-enter-done',
  [k.CARD_EXIT]: 'card-exit',
  [k.CARD_EXIT_ACTIVE]: 'card-exit-active',
  [k.CARD_EXIT_DONE]: 'card-exit-done',
  [k.SEE_LESS]: 'See Less',
  [k.SEE_ALL]: 'See All',
  [k.VW6]: '20vw',
  [k.RGBA4]: 'rgba(0,0,0,0.25)',
  [k.FIND_OUT_HOW_MUCH_YOUR_HEARING]:
    'Find out how much your hearing aids are worth',
  [k.HIDDENBRAND]: 'hiddenBrand',
  [k.BRAND]: 'brand',
  [k.ENTER_BRAND]: 'Enter Brand',
  [k.GET_ESTIMATE]: 'Get Estimate',
  [k.BY_SUBMITTING_THE_FORM_I_AGRE]:
    "By submitting the form, I agree to the Orka's",
  [k.YOUR_HEARING_AIDS_ARE_WORTH_BE]: 'Your hearing aids are worth between',
  [k.GET_YOUR_VOUCHER]: 'Get Your Voucher',
  [k.ESTIMATE_MY_VALUE_AGAIN]: 'Estimate my value again',
  [k.PX_PX21]: '32px 48px',
  [k.PX_PX22]: '32px 16px',
  [k.PX157]: '0 0 20px 0',
  [k.PX_PX23]: '10px 22px',
  [k.PX_PX24]: '10px 15px',
  [k.PX158]: '5px 0',
  [k.PHONAK]: 'Phonak',
  [k.OTICON]: 'Oticon',
  [k.WIDEX]: 'Widex',
  [k.SIGNIA]: 'Signia',
  [k.RESOUND]: 'Resound',
  [k.STARKEY]: 'Starkey',
  [k.EARGO]: 'Eargo',
  [k.LIVELY]: 'Lively',
  [k.KIRKLAND_COSTCO]: ' Kirkland(Costco)',
  [k.PLEASE_ENTER_YOUR_HEARING_AIDS]: 'Please enter your hearing aids brand',
  [k.PLEASE_ENTER_A_VALID_YEAR_OF_P]: 'Please enter a valid year of purchase',
  [k.PLEASE_ENTER_YOUR_FIRST_AND_LA]: 'Please enter your first and last name',
  [k.PLEASE_ENTER_YOUR_EMAIL]: 'Please enter your Email',
  [k.PLEASE_PROVIDE_A_VALID_EMAIL]: 'Please provide a valid email',
  [k.PLEASE_ENTER_YOUR_PHONE]: 'Please enter your phone',
  [k.PLEASE_PROVIDE_A_VALID_PHONE_N]: 'Please provide a valid phone number',
  [k.ORKA_ONE_REVIEWS]: 'Orka One reviews',
  [k.READ_OUR_REVIEWS]: 'Read our reviews',
  [k._103]: '4.7 - 5',
  [k.FIRST]: 'first',
  [k.LAST]: 'last',
  [k.THANK_YOU_FOR_SUBMITTING]: 'Thank you for submitting!',
  [k.OUR_HEARING_SPECIALIST_WILL_RE]:
    'Our hearing specialist will reach out to you as soon as possible!',
  [k.CALL]: 'Call: +1(866) 419 7760',
  [k.EMAIL_US1]: 'Email us:',
  [k.MEET_OUR_AUDIOLOGISTS_TO_APPLY]:
    'Meet our audiologists to apply your voucher !',
  [k.COMMON]: 'Common',
  [k.QUESTIONS]: 'Questions',
  [k.FIND_OUT_HOW_MUCH_YOU_CAN_GET]:
    'Find out how much you can get by trade in your old hearing aid(s)',
  [k.PX159]: '-5px',
  [k.PX160]: '68px',
  [k.INTELLIGENT_HEARING_SOLUTIONS]:
    'Intelligent hearing solutions available at affordable price',
  [k.EXPLORE_ORKA_ONE]: 'Explore Orka One',
  [k.CONTAINER]: 'container',
  [k.SRC_IMGS_LANDING_SUBSCRIBE_POS]: '/imgs/Landing/subscribe_poster_pc.jpg',
  [k.STATIC]: 'static',
  [k.PX161]: '46px',
  [k._104]: '1000',
  [k.AUDIO]: 'audio',
  [k._105]: '•',
  [k.HAVE_DIFFICULTIES_DURING_USING]:
    'Have difficulties during using Orka one?',
  [k.YOU_HAVE_THE_ORKA_ONE_DAY_T]:
    'You have the Orka one 45-day trial period.You can consult with our customer service staff first.',
  [k.RETURN_SUMMARY]: 'Return summary',
  [k.ORDER_NUMBER6]: 'Order number:0067',
  [k.REFUND_XXXXX]: 'Refund: $xxxxx',
  [k.QUANTITY3]: 'Quantity:',
  [k.UPLOAD_IMAGE]: 'Upload image',
  [k.WHY_DID_THIS_ITEM_NOT_MEET_YOU]:
    'Why did this item not meet your expectations?',
  [k.UPLOAD_PICTURES_FOR_THE_RETURN]:
    'Upload pictures for the return item(max 6 pictures)',
  [k.ORDER_NUMBER_XXX]: 'Order number xxx',
  [k.CANCELED_JAN]: 'Canceled 18 Jan 2021',
  [k.YOU_CANCELED_THIS_ORDR_ON_J]: 'You canceled this ordr on 18 Jan 2021.',
  [k.HERE_S_YOUR_REFUND_PROCESS]: "Here's your refund process.",
  [k.APPLY_SUCCESSFULLY]: 'Apply successfully',
  [k.APPLY_FOR_RETURN_SUCCESSFULLY]:
    'Apply for return successfully.  We will contact you to complete the return as soon as possible.',
  [k.THE_FOLLOWING_ITEM_HAS_BEEN_AP]:
    'The following item has been applied for return:',
  [k.YOU_DON_T_HAVE_TO_PAY_FOR_THIS]: "You don't have to pay for this item",
  [k.PERSONAL_INFORMATION]: 'Personal information',
  [k.NAME_DI_GAO]: 'Name: Di Gao',
  [k.PHONE_NUMBER3]: 'Phone number: 123-496-4890',
  [k.EMAIL_ADDRESS_EHUDHW_GMAIL_CO]: 'Email address: ehudhw@gmail.com',
  [k.AVAILABLE_TIME_WEEKDAYS]: 'Available time: weekdays, 13:-19:00',
  [k.PRINT_RETURN_LABLE]: 'PRINT RETURN LABLE',
  [k.CANCEL_THE_ORDER_WHY_NOT_TRY]: 'Cancel the order? Why not try it first!',
  [k.TRY_ORKA_ONE_WITH_DAY_TRIAL]: 'Try Orka one with 45-day trial period.',
  [k._106]: '0067',
  [k.REFUND2]: 'Refund:',
  [k.XXXXX]: '$xxxxx',
  [k.I_DON_T_WANT_IT1]: "I don't want it.",
  [k.SUMBIT]: 'Sumbit',
  [k.CANCELED]: 'Canceled',
  [k.CANCELLED_SUCCESSFULLY_THE_RE]:
    'Cancelled Successfully, the refund will be returned within xx days.',
  [k.THE_FOLLOWING_ITEM_HAS_BEEN_CA]: 'The following item has been canceled',
  [k.CANCELED_SUCCESSFULLY]: 'Canceled successfully',
  [k.IF_YOU_HAVE_ANY_QUESTIONS_YOU]:
    'If you have any questions, you can chat with us online.',
  [k.WHERE_DO_I_FIND_MY_ORDER_NUM]: 'Where Do I Find My Order Number?',
  [k.DO_NOT_HAVE_ACCOUNT]: 'Do not Have an Orka Account?',
  [k.FOR]: 'For',
  [k.ORDER_NUM_CAN_BE_FOUND_FROM]: ', order number can be found from the',
  [k.ORDER_CONFIRMATION_EMAIL]: 'Order Confirmation Email',
  [k.ORKA_HAS_SENT_PREVIOUSLY_UPON_PURCHASE]:
    'Orka has sent previously upon purchase.',
  [k.ALREADY_HAVE_AN_ORKA_ACCOUNT]: 'Already have an Orka account?',
  [k.YOU_CAN_ALSO_LOG_INTO_YOUR]: 'You can also log into your',
  [k.ORKA_ACCOUNT]: 'Orka Account',
  [k.GO_TO_THE_ACCOUNT_MENU_AND_SELECT]: ', go to the account menu and select',
  [k.AND_VIEW_DETAILS_OF_ALL_YOUR_ORDERS]:
    'and view details of all your orders and the order numbers.',
  [k.FIND_MY_ORDER]: 'Find My Order',
  [k.HOW_DO_I_FIND_MY_ORDER_NUMBER]: 'How Do I Find My Order Number?',
  [k.OR_LOG_IN_TO_SEE_YOUR_ORDER_HISTORY]:
    'Or Log In To See Your Order History',
  [k.ORDER_TOTAL]: 'Order Total',
  [k.UPLOAD_YOUR_AUDIOGRAM]: 'Upload your audiogram',
  [k.UPLOAD_YOUR_AUDIOGRAM_CONTENT]: `If available, an audiogram is kindly requested to help us personalize your Orka. You can also customize your Orka later via the app if you don't have one yet.`,
  [k.GO_TO_UPLOAD]: 'GO TO UPLOAD'
}
